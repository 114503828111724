import * as React from 'react';
import { range } from 'lodash';

import './styles.scss';
import { loadingBarHexColors } from 'client/shared/core/colors';

const barCount = 6;

export interface Props {
  readonly className?: string;
}

export const LoaderBars: React.FC<Props> = (p) => {
  return (
    <div className={`loader-bars ${p.className || ''}`}>
      {range(barCount).map((i) => (
        <div
          className="loader-bar"
          key={i}
          style={{
            backgroundColor: loadingBarHexColors[i % loadingBarHexColors.length],
          }}
        />
      ))}
    </div>
  );
};
