import { prefixScript, Nonce } from '../util';

let hubspotInitialized = false;

export function isHubspotInitialized() {
  return hubspotInitialized;
}

export function hubspotInit(hsAccountId: string, nonce: Nonce) {
  if (hubspotInitialized) return;
  hubspotInitialized = true;
  prefixScript(nonce, `https://js.hs-scripts.com/${hsAccountId}.js`, {
    async: true,
    defer: true,
    title: 'hubspot',
  });
}
