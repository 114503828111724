import { GraphQLError } from 'graphql';
import { Case, unionOfEnum } from './helpers';

export interface GqlError<T> {
  readonly graphQLErrors: readonly T[];
}

export interface ApiError {
  readonly statusCode: number;
  readonly name?: string;
  readonly message?: string;
  readonly stack?: string;
}

export class PolcoGqlError<T extends AllPolcoGqlErrorTypes> extends GraphQLError {
  constructor(
    public readonly extra: GqlErrorExtra<T>,
    message?: string
  ) {
    super(message ?? '');
  }
}

export function isExpectedError(
  e: Error
): e is PolcoGqlError<AllPolcoGqlErrorTypes> {
  return 'extra' in e;
}

export enum UpdateShortUrlFailureReason {
  SHORT_URL_ALREADY_IN_USE = 'Short URL is already taken',
  SHORT_URL_TOO_SHORT = 'Short URL too short or empty',
  INVALID_URL = 'Invalid url',
  INVALID_BASE_URL = 'Invalid base url',
}

export enum PublishingEntityFailureReason {
  ERROR_DELETING = 'There was an error while removing the profile. If the problem persists, please contact the Engineering Team.',
}

export enum ApolloErrorMessage {
  ADMIN_NOT_FOUND = 'ADMIN_NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  RESPONDENT_NOT_FOUND = 'RESPONDENT_NOT_FOUND',
  OUTCOME_NOT_FOUND = 'OUTCOME_NOT_FOUND',
  REGISTRATION_NOT_FOUND = 'REGISTRATION_NOT_FOUND',
  INVALID_QUESTION_SET_TYPE = 'INVALID_QUESTION_SET_TYPE',
  QUESTION_SET_NOT_FOUND = 'QUESTION_SET_NOT_FOUND',
  QUESTION_NOT_FOUND = 'QUESTION_NOT_FOUND',
  PUBLISHING_ENTITY_NOT_FOUND = 'PUBLISHING_ENTITY_NOT_FOUND',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
  INVALID_BALANCING_ACT_CONTENT_TYPE = 'INVALID_BALANCING_ACT_CONTENT_TYPE',
  PUB_MISSING_FIPS = 'PUB_MISSING_FIPS',
  EMAIL_VERIFICATION_REQUIRED = 'EMAIL_VERIFICATION_REQUIRED',
  PUB_MISSING_BA_CLIENT_ID = 'PUB_MISSING_BA_CLIENT_ID',
}

export interface SqlError {
  readonly code: string;
  readonly column?: string;
  readonly constraint?: string;
  readonly dataType?: string;
  readonly detail?: string;
  readonly file: string;
  readonly hint?: string;
  readonly internalPosition?: any;
  readonly internalQuery?: any;
  readonly length: number;
  readonly line: string;
  readonly message: string;
  readonly name: string;
  readonly position?: any;
  readonly routine: string;
  readonly schema?: any;
  readonly severity: 'ERROR' | 'WARNING'; // ?
  readonly stack: string;
  readonly table?: string;
  readonly where: string;
}

export interface GqlErrorExtra<T = unknown> {
  readonly status: number;
  readonly errors: T;
}

export type AllPolcoGqlErrorTypes =
  | PolcoGqlErrors.ClientUpdateProfileFailureReason
  | PolcoGqlErrors.ThirdPartyMappingError
  | PolcoGqlErrors.RemoveAdminFromPublishingEntityFailureReason
  | PolcoGqlErrors.CreateRegistrationAdminUserFailure
  | PolcoGqlErrors.CreateRegistrationProfileFailureReason
  | UpdateShortUrlFailureReason
  | readonly PolcoGqlErrors.ShortUrlError[]
  | PolcoGqlErrors.BalancingActSimulationResponseError
  | PolcoGqlErrors.PolcoApiError
  | PolcoGqlErrors.RespondentVotingFailureReason
  | PolcoGqlErrors.AiAssistantResponseError
  | PolcoGqlErrors.SuperAdminUpdatePublisherError;

export namespace PolcoGqlErrors {
  export interface PolcoApiError extends Omit<ApiError, 'stack'> {}

  export enum ThirdPartyMappingError {
    THIRD_PARTY_RECORD_IDENTIFIER_ALREADY_EXISTS = 'THIRD_PARTY_RECORD_IDENTIFIER_ALREADY_EXISTS',
    NOT_FOUND = 'NOT_FOUND',
  }

  export enum RemoveAdminFromPublishingEntityFailureReason {
    PUBLISHER_NOT_FOUND = 'PUBLISHER_NOT_FOUND',
    NO_PERMISSON_ON_PUBLISHER = 'NO_PERMISSON_ON_PUBLISHER',
    NOT_ALLOWED_TO_DELETE_YOURSELF = 'NOT_ALLOWED_TO_DELETE_YOURSELF',
    COULD_NOT_DELETE_PERM = 'COULD_NOT_DELETE_PERM',
    COULD_NOT_DELETE_USER_PERMISSIONS = 'COULD_NOT_DELETE_USER_PERMISSIONS',
    NOT_FOUND_USER = 'NOT_FOUND_USER',
    NOT_FOUND_ADMIN = 'NOT_FOUND_ADMIN',
  }

  export enum ClientUpdateProfileFailureReason {
    NO_RESPONDENT = 'NO_RESPONDENT',
    EMAIL_TAKEN = 'EMAIL_TAKEN',
    NO_USER = 'NO_USER',
    DEFAULT = 'DEFAULT',
  }

  export enum CreateRegistrationAdminUserFailureReason {
    EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  }

  export enum CreateRegistrationProfileFailureReason {
    PUBLISHING_ENTITY_ALREADY_CLAIMED = 'PUBLISHING_ENTITY_ALREADY_CLAIMED',
  }

  export enum CopyContentSetFailureReason {
    CONTENT_SET_NOT_FOUND = 'SURVEY_NOT_FOUND',
    QUESTION_CHOICE_SET_NOT_FOUND = 'QUESTION_CHOICE_SET_NOT_FOUND',
    INVALID_INPUT_PARAMS = 'INVALID_INPUT_PARAMS',
    NAME_ALREADY_TAKEN = 'NAME_ALREADY_TAKEN',
    META_CONDITION_NOT_FOUND = 'META_CONDITION_NOT_FOUND',
    HIERARCHY_CONDITION_NOT_FOUND = 'HIERARCHY_CONDITION_NOT_FOUND',
    QUESTION_CHOICE_CONDITION_DATA_NOT_FOUND = 'QUESTION_CHOICE_CONDITION_DATA_NOT_FOUND',
    ADMIN_CANT_COPY_POLCO_MANAGED_CONTENT = 'ADMIN_CANT_COPY_POLCO_MANAGED_CONTENT',
    NOT_DEFINED_SOURCE_LIVE_SETTING_FOR_LIVE_QUESTION_SET = 'NOT_DEFINED_SOURCE_LIVE_SETTING_FOR_LIVE_QUESTION_SET',
    NOT_DEFINED_TARGET_LIVE_SETTING_FOR_LIVE_QUESTION_SET = 'NOT_DEFINED_TARGET_LIVE_SETTING_FOR_LIVE_QUESTION_SET',
    HIERARCHY_VISUALIZATION_NOT_FOUND = 'HIERARCHY_VISUALIZATION_NOT_FOUND',
    HIERARCHY_SIMULATION_NOT_FOUND = 'HIERARCHY_SIMULATION_NOT_FOUND',
    COPY_PUB_NOT_SAME_AS_CURRENT_PUB_FOR_POST = 'COPY_PUB_NOT_SAME_AS_CURRENT_PUB_FOR_POST',
  }

  export type CreateRegistrationAdminUserFailure =
    | Case<CreateRegistrationAdminUserFailureReason.EMAIL_ALREADY_IN_USE>
    | Case<
        CreateRegistrationAdminUserFailureReason.INVALID_PASSWORD,
        { readonly errorMessage: string }
      >
    | Case<CreateRegistrationAdminUserFailureReason.UNKNOWN_ERROR>;

  export const CreateRegistrationAdminUserFailure = unionOfEnum(
    CreateRegistrationAdminUserFailureReason,
    { ...CreateRegistrationAdminUserFailureReason }
  ).andType<CreateRegistrationAdminUserFailure>();

  export interface ShortUrlError {
    readonly type: UpdateShortUrlFailureReason;
    readonly fullUrl: string;
    readonly shortUrl: string;
  }

  export enum SuperAdminUpdatePublisherError {
    CUSTOMER_DOES_NOT_EXIST = 'CUSTOMER_DOES_NOT_EXIST',
    PUBLISHING_ENTITY_NOT_FOUND = 'PUBLISHING_ENTITY_NOT_FOUND',
    COULD_NOT_CREATE_ENVISO_USER = 'COULD_NOT_CREATE_ENVISO_USER',
    DB_ERROR = 'DB_ERROR',
  }

  export enum BalancingActSimulationResponseError {
    NO_SIMS_FOUND = 'NO_SIMS_FOUND',
    CUSTOMER_DOES_NOT_EXIST = 'CUSTOMER_DOES_NOT_EXIST',
    NO_TAX_RECEIPT_FOUND = 'NO_TAX_RECEIPT_FOUND',
  }
  export enum AiAssistantResponseError {
    AI_ASSISTANT_INTERACTIONS_NOT_FOUND = 'AI_ASSISTANT_INTERACTIONS_NOT_FOUND',
  }

  export enum RespondentVotingFailureReason {
    CHOICES_DONT_MATCH_QUESTION_CHOICES = 'CHOICES_DONT_MATCH_QUESTION_CHOICES',
  }
}

export class EnvironmentError extends Error {
  constructor(message: string) {
    super(message);
  }
}
