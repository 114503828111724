import * as React from 'react';
import dompurify from 'dompurify';
import { getConfig } from './config';
import { BalancingActSimulationType, buildUrlUtils, QuestionSetType } from 'core';
import { ClientQuestionId } from './question';
import { HTMLReactParserOptions } from 'html-react-parser';
import { ANALYTIC_USER_TYPE, ANALYTICS_CONTENT_TYPE, KeyPressKey } from './types';
import { ClientQuestionSetId } from './question-set';

export enum setTypeForURL {
  POLL_SET = 'set',
  SURVEY = 'survey',
  CONTENT_POST = 'content-post',
  POLCO_LIVE = 'live-event',
}

export const ClientUrlUtils = buildUrlUtils({
  siteUrl: getConfig().siteUrl,
  balancingActSiteUrl: getConfig().envFeatures.balancingActAdminBaseUrl,
  includeBasePath: false,
  // can't base off node_env here, it'll get baked in
  // but as long as siteUrl is set correctly it won't matter
  forceHttps: false,
});

export function analyticsClass(
  targerUser: ANALYTIC_USER_TYPE,
  contentType: ANALYTICS_CONTENT_TYPE,
  extra: string
) {
  return `pn-analytics-${targerUser}-${contentType}-${extra}`;
}

export function questionSetTypeToAnalyticsContentType(type: QuestionSetType) {
  switch (type) {
    case QuestionSetType.POLCO_LIVE:
      return ANALYTICS_CONTENT_TYPE.LIVE_EVENT;
    case QuestionSetType.SET:
      return ANALYTICS_CONTENT_TYPE.POLLS;
    case QuestionSetType.SURVEY:
      return ANALYTICS_CONTENT_TYPE.SURVEY;
    case QuestionSetType.CONTENT_POST:
      return ANALYTICS_CONTENT_TYPE.CONTENT_POST;
  }
}

export interface MouseFlowType {
  readonly tag: string;
  readonly pageView?: string;
  readonly customVariable?: {
    readonly key: string;
    readonly value: string;
  };
}

export function handlePressEnter(
  cb: (evt: React.KeyboardEvent<any>) => void | Promise<any> | false
): ((e: React.KeyboardEvent<any>) => void | Promise<any> | false) | undefined {
  return (e) => {
    if (e.key === KeyPressKey.ENTER) {
      return cb(e);
    }
  };
}

// Detects if device is on iOS Safari
export const isIOSSafari = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return (
    /iphone|ipad|ipod/.test(userAgent) &&
    /safari/.test(userAgent) &&
    !/crios/.test(userAgent)
  );
};

// Detects if device is in standalone mode
export const isInStandaloneMode = () =>
  'standalone' in window.navigator && (window.navigator as any)['standalone'];

// Check if APP is in localhost
export const isLocalHost = Boolean(
  getConfig().localhostRegexes.find((rx: string) => {
    return new RegExp(rx).test(window.location.origin);
  })
);

export function votingPathForQuestion(
  set: {
    readonly id: ClientQuestionSetId;
    readonly slug: string | null;
    readonly pubSlug: string;
    readonly type: QuestionSetType.POLCO_LIVE | QuestionSetType.SET;
  },
  questionId: ClientQuestionId
): string {
  const rx2Path = `/n${ClientUrlUtils.respondent.question.path({
    questionId,
    setIdOrSlug: set.slug ?? set.id,
    pubSlug: set.pubSlug,
    setType: set.type,
  })}`;

  return rx2Path;
}

export function sanitizeHtml(html: string) {
  dompurify.addHook('uponSanitizeElement', (node, event) => {
    if (event.tagName !== 'iframe') return;
    const src = node.getAttribute('src') ?? '';
    const prefixes = ['https://', '', 'www.', 'https://www.'];
    const allowList = [
      'youtube.com',
      'arcgis.com',
      'maps.arcgis.com',
      'player.vimeo.com',
    ];
    const combinations = prefixes.flatMap((prefix) => {
      return allowList.map((domain) => {
        return `${prefix}${domain}`;
      });
    });
    if (!combinations.some((url) => src.startsWith(url))) {
      return node.parentNode?.removeChild(node);
    }
  });
  dompurify.addHook('afterSanitizeAttributes', (node) => {
    if ('target' in node && node.target === '_blank') {
      node.setAttribute('rel', 'noreferrer');
    }
  });

  return dompurify.sanitize(html, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: [
      'allow',
      'allowfullscreen',
      'width',
      'height',
      'src',
      'frameborder',
      'scrolling',
      'target',
    ],
  });
}

export const parseOptions = (alt: string): HTMLReactParserOptions => {
  return {
    replace: (domNode) => {
      //used to resize text and images to look well-formatted on all devices
      if ('attribs' in domNode && domNode.attribs && domNode.name === 'img') {
        return (
          <img
            alt={domNode.attribs.alt ?? alt}
            className="img-fluid"
            height={domNode.attribs.height}
            src={domNode.attribs.src}
            style={styleStringToObject(domNode.attribs.style ?? '')}
            width={domNode.attribs.width}
          />
        );
      }
    },
  };
};

function styleStringToObject(styleString: string) {
  return styleString.split(';').reduce((acc, style) => {
    const colonPosition = style.indexOf(':');

    if (colonPosition === -1) {
      return acc;
    }

    // We need to convert the properties to camel case.
    // Note this will not currently work for vendor prefixed styles
    const camelCaseProperty = style
      .substr(0, colonPosition)
      .trim()
      .replace(/-./g, (c) => c.substr(1).toUpperCase());
    const value = style.substr(colonPosition + 1).trim();

    if (!camelCaseProperty || !value) {
      return acc;
    }

    return {
      ...acc,
      [camelCaseProperty]: value,
    };
  }, {});
}

export const validateEmail = (email: string) => {
  const rx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return rx.test(email.toLowerCase());
};

export const pasteSplit = (data: string) => {
  const separators = [
    ' ',
    ',',
    ';',
    '\\(',
    '\\)',
    '\\*',
    '/',
    ':',
    '\\?',
    '\n',
    '\r',
  ];
  return data.split(new RegExp(separators.join('|'))).map((d) => d.trim());
};

export function copyContentToClipboard(content: string) {
  const el = document.createElement('textarea');
  el.value = content;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export function sanitizeElementSelector(id: string) {
  return id.replaceAll(' ', '_').replaceAll('.', '');
}

export function getUrlForSimulationType(
  simId: number,
  type: BalancingActSimulationType
) {
  switch (type) {
    case BalancingActSimulationType.RECEIPT:
      return ClientUrlUtils.balancingAct.editBalancingActReceiptSimulation(simId);
    case BalancingActSimulationType.BUDGET:
      return ClientUrlUtils.balancingAct.editBalancingActSimulation(simId);
  }
}
