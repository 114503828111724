import {
  prefixScript,
  Nonce,
  triggerSocialProviderInitializationCallbacks,
} from '../util';
// NOTE: implemented here to minimize dependencies for analytics bootstrap script
export function mapKeys<T extends string | number, U extends string | number, V>(
  r: Record<T, V>,
  fn: (t: T) => U
): Record<U, V> {
  const out = {} as Record<U, V>;
  for (const k in r) {
    out[fn(k)] = r[k];
  }
  return out;
}

function merge<T1, T2>(_arg1: T1, _arg2: T2): T1 & T2;
function merge<T1, T2, T3>(_arg1: T1, _arg2: T2, _arg3: T3): T1 & T2 & T3;
function merge(...args: readonly any[]): any {
  const out = {} as any;
  for (const a of args) {
    for (const k in a) {
      out[k] = a[k];
    }
  }
  return out;
}

let gAuthInitialized = false;

export function isGAuthInitialized() {
  return gAuthInitialized;
}

export type GABuiltinActions =
  | 'add_payment_info' // 	  ecommerce
  | 'add_to_cart' //          ecommerce
  | 'add_to_wishlist' //      ecommerce
  | 'begin_checkout' //       ecommerce
  | 'checkout_progress' //    ecommerce
  | 'generate_lead' //        engagement
  | 'login' //                engagement 	 label_type: method
  | 'purchase' //             ecommerce
  | 'refund' //               ecommerce
  | 'remove_from_cart' //     ecommerce
  | 'search' //               engagement 	 label_type: search_term
  | 'select_question' //       engagement 	 label_type: question_type
  | 'set_checkout_option' //  ecommerce
  | 'share' //                engagement 	 label_type: method
  | 'sign_up' //              engagement 	 label_type: method
  | 'view_item' //            engagement
  | 'view_item_list' //       engagement
  | 'view_promotion' //       engagement
  | 'view_search_results'; // engagement 	 label_type: search_term

export type GAPolcoActions =
  | 'viewed_question'
  | 'viewed_loading_question'
  | 'choice_selected'
  | 'attempted_vote'
  | 'voted'
  | 'prompted_registration'
  | 'commented'
  | 'attempted_upvote'
  | 'upvoted'
  | 'undid_upvote'
  | 'session_ended'
  | 'viewed_todo'
  | 'clicked_todo'
  | 'dismissed_todo'
  | 'suppressed_todo'
  | 'completed_todo';

export type GAActionTypes = GABuiltinActions | GAPolcoActions;

export type GACategories = 'voting' | 'admin';

export enum GAPolcoDimensions {
  LOGGED_IN = 'logged_in',
  POLICY_TYPE = 'policy_type',
  VOTING_EXPERIMENT_ITERATION = 'voting_experiment_iteration',
  VOTING_BOUNCE = 'voting_bounce',
  TODO_ITEM = 'todo_item',
}

export enum GAPolcoMetrics {
  ACTIVITY_SESSION_LENGTH = 'activity_session_length',
}

export type GACustomDimensionValues = {
  readonly [k in GAPolcoDimensions]?: string | boolean;
};

export type GACustomMetricsValues = { readonly [j in GAPolcoMetrics]?: number };

const dimensionsMap: Record<number, GAPolcoDimensions> = {
  2: GAPolcoDimensions.LOGGED_IN,
  3: GAPolcoDimensions.POLICY_TYPE,
  4: GAPolcoDimensions.VOTING_EXPERIMENT_ITERATION,
  5: GAPolcoDimensions.VOTING_BOUNCE,
  6: GAPolcoDimensions.TODO_ITEM,
};

const metricsMap: Record<number, GAPolcoMetrics> = {
  1: GAPolcoMetrics.ACTIVITY_SESSION_LENGTH,
};

interface DimMap {
  readonly [dimName: string]: string;
}
const customMap: DimMap = merge(
  mapKeys(dimensionsMap, (k) => `dimension${k}`),
  mapKeys(metricsMap, (k) => `metric${k}`)
);

export interface GAEvent {
  readonly action: GAActionTypes;
  readonly category: GACategories;
  readonly label?: string;
  readonly value?: number; // integer
  readonly custom?: GACustomDimensionValues & GACustomMetricsValues;
}

export function gtagEvent(evt: GAEvent) {
  gtagBootstrap();
  const gtagF = (window as any).gtag;
  gtagF &&
    gtagF('event', evt.action, {
      ...evt.custom,
      event_category: evt.category,
      event_label: evt.label,
      value: evt.value,
    });
}

function gtagBootstrap() {
  if (!(window as any).gtag) {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).gtag = function () {
      (window as any).dataLayer.push(arguments);
    };
  }
}

let googleInitialized = false;

export function isGoogleInitialized() {
  return googleInitialized;
}

export function googleInit(
  keys: {
    readonly trackingId: string | null;
    readonly gtmId: string | null;
    readonly mapApiKey: string | null;
  },
  domains: readonly string[],
  nonce: Nonce
) {
  if (googleInitialized) return;
  googleInitialized = true;

  prefixScript(
    nonce,
    'https://accounts.google.com/gsi/client',
    {},
    {
      onloadFunction(this: GlobalEventHandlers) {
        gAuthInitialized = true;
        triggerSocialProviderInitializationCallbacks();
        this.onload = function () {};
      },
      onreadystatechangeFunction(this: HTMLScriptElement) {
        if ((this as any).readyState === 'complete') {
          this.onload && this.onload({} as Event);
        }
      },
    }
  );
  if (keys.trackingId) {
    gtagBootstrap();
    const oldDataLayer = (window as any).dataLayer;
    (window as any).dataLayer = [];
    (window as any).gtag('js', new Date());
    (window as any).gtag('config', keys.trackingId, {
      custom_map: customMap,
      linker: { domains },
    });
    for (const item of oldDataLayer) {
      (window as any).dataLayer.push(item);
    }
    prefixScript(
      nonce,
      `https://www.googletagmanager.com/gtag/js?id=${keys.trackingId}`,
      { async: true }
    );
  }
  if (keys.gtmId) {
    gtagBootstrap();
    (window as any).dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    prefixScript(nonce, `https://www.googletagmanager.com/gtm.js?id=${keys.gtmId}`, {
      async: true,
    });
  }
  if (keys.mapApiKey) {
    (window as any).onMapLoaded = function () {
      (window as any).mapLoaded = true;
    };
    prefixScript(
      nonce,
      `https://maps.googleapis.com/maps/api/js?key=${keys.mapApiKey}&libraries=places&language=en&callback=onMapLoaded`
    );
  }
}
