import { Brand } from 'core';
import { SharedIntegrationConfigKeys } from 'config';
import { getConfig } from 'client/shared/core/config';

export type Nonce = Brand<string, 'nonce'>;

export function toNonce(value: string): Nonce {
  return value as Nonce;
}

export function makePixel(...urls: readonly string[]) {
  const noscript = document.createElement('noscript');
  urls.forEach((url) => {
    const img = document.createElement('img') as HTMLImageElement;
    img.height = 1;
    img.width = 1;
    img.style.display = 'none';
    img.src = url;
    img.alt = '';
    noscript.appendChild(img);
  });
  document.getElementsByTagName('body')[0].appendChild(noscript);
}

function loadError(oError: Event | string) {
  if (typeof oError === 'string') {
    throw new URIError("The script didn't load correctly.\n" + oError);
  } else {
    const target = oError.target as HTMLScriptElement;
    throw new URIError(
      'The script ' + target.src + " didn't load correctly.\n" + oError
    );
  }
}

export function prefixScript(
  nonce: Nonce,
  url: string,
  // eslint-disable-next-line functional/prefer-readonly-type
  attributes?: { [attr: string]: any },
  handlers?: {
    readonly onloadFunction?: HTMLElement['onload'];
    readonly onreadystatechangeFunction?: Function;
  }
) {
  const newScript = document.createElement('script') as HTMLScriptElement;
  newScript.onerror = loadError;
  if (attributes && attributes['async']) {
    newScript.async = attributes['async'];
    delete attributes['async'];
  }
  newScript.setAttribute('nonce', nonce);
  if (attributes) {
    Object.keys(attributes).forEach((attr) => {
      newScript.setAttribute(attr, attributes[attr]);
    });
  }
  if (handlers && handlers.onloadFunction) {
    newScript.onload = handlers.onloadFunction;
  }
  if (handlers && handlers.onreadystatechangeFunction) {
    (newScript as any).onreadystatechange = handlers.onreadystatechangeFunction;
  }
  document.head && document.head.insertBefore(newScript, document.head.lastChild);
  newScript.src = url;
}

export function prefixStyle(url: string) {
  const newStyle = document.createElement('link') as HTMLLinkElement;
  newStyle.setAttribute('rel', 'stylesheet');
  newStyle.setAttribute('type', 'text/css');
  document.head && document.head.insertBefore(newStyle, document.head.lastChild);
  newStyle.href = url;
}

/**
 * Retrieve the list of domains which should be auto-linked. At the moment this very
 * lazy retrieves the siteUrl and strips off `https://`. We don't technically
 * need to include overview.polco.us here because polco.us matches for overview.polco.us
 * for domains.
 * @returns the list of domains
 */
export function getDomains() {
  const url = getConfig().siteUrl;
  const split = url.split('//');
  const domain = split[split.length - 1];
  return [domain];
}

export function getIntegrationConfigKey<K extends keyof SharedIntegrationConfigKeys>(
  k: K
): SharedIntegrationConfigKeys[K] | null {
  const v = getConfig().integrations[k];
  if (v && v.length) {
    return v;
  } else {
    return null;
  }
}

const initializationCallbacks = new Set<() => void>();

export function onSocialProviderInitialization(cb: () => void) {
  initializationCallbacks.add(cb);
}

export function triggerSocialProviderInitializationCallbacks() {
  for (const cb of initializationCallbacks.values()) {
    cb();
  }
}

export function socialWindow(url: string) {
  const left = (screen.width - 570) / 2;
  const top = (screen.height - 570) / 2;
  const params =
    'menubar=no,toolbar=no,status=no,width=570,height=570,top=' +
    top +
    ',left=' +
    left;
  window.open(url, 'NewWindow', params);
}
