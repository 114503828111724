import * as React from 'react';
import { BtnLink, ButtonTypes } from '../base';
import './styles.scss';

export enum Type {
  ANCHOR = 'ANCHOR',
  BUTTON = 'BUTTON',
}

const baseClass = 'pn-mail-to';

export const MailTo: React.FC<{
  readonly email: string;
  readonly subject?: string;
  readonly body?: string;
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly boldFont?: boolean;
  readonly type?: Type;
}> = (p) => {
  const {
    email,
    subject,
    body,
    children,
    className,
    boldFont,
    type = Type.ANCHOR,
  } = p;

  const mailtoComponentStrategy = {
    [Type.ANCHOR]: (
      <a
        className={`${baseClass} ${className ?? ''} ${
          boldFont ? 'font-weight-bold' : ''
        }`}
        href={`mailto:${email}?subject=${encodeURIComponent(
          subject ?? ''
        )}&body=${encodeURIComponent(body ?? '')}`}
        target="_blank"
      >
        {children}
      </a>
    ),

    [Type.BUTTON]: (
      <BtnLink
        className={`${className ?? ''} well-right-button-content-link`}
        iconOnly={false}
        to={`mailto:${email}?subject=${encodeURIComponent(
          subject ?? ''
        )}&body=${encodeURIComponent(body ?? '')}`}
        type={ButtonTypes.SECONDARY}
      >
        {children}
      </BtnLink>
    ),
  };

  return mailtoComponentStrategy[type];
};
