import { default as _slugify } from 'slugify';

export function enforceHttps(url: string): string {
  return `https://${url.replace(/^https?:\/\//i, '')}`;
}

/**
 * Turn some sort of text into a slug, using our "standard rules". Basically,
 * if you need to truncate, start looking at 3/4 of max-length and continue
 * forward until you reach the end of that token. It makes us suspectible to
 * `ReallyLongNameWithNoPunctuationWhatsoeverButWhoIsGonnaDoThisAnyway`, but we can
 * fix that case later if it ever comes up
 * @param text the text value to slugify
 * @param targetMaxLength the ideal max length of the slug. Basically we start
 * trying to truncate slightly before we hit this length limit
 * @returns a slug string for the input text
 */
export function slugify(text: string, targetMaxLength: number): string {
  const slug = _slugify(text, {
    replacement: '-',
    remove: /[*+~.()'"!:@]/g,
    lower: true,
    strict: true,
  });
  if (slug.length <= targetMaxLength) {
    return slug;
  }
  const startIndex = Math.floor(targetMaxLength * 0.75);
  const index = slug.indexOf('-', startIndex);
  return slug.substring(0, index < 0 ? targetMaxLength : index);
}

const TARGET_PLACENAME_SLUG_LENGTH = 30;
export function slugifyPublisherName(pubName: string): string {
  return slugify(pubName, TARGET_PLACENAME_SLUG_LENGTH);
}

/**
 * Starting with 0=A, translates any positive integer into an uppercase Alpha
 *
 * @param index 0+
 */
export function indexToLetter(index: number) {
  return ((index % 26) + 10).toString(36).toUpperCase();
}
