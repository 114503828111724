/* eslint no-param-reassign: "off" */

declare global {
  interface Window {
    // eslint-disable-next-line functional/prefer-readonly-type
    heap: any;
  }
}

let heapInitialized = false;

function withHeap(cb: (h: any) => void) {
  if (heapInitialized) {
    cb(window.heap);
  } else if (
    process.env.NODE_ENV !== 'development' &&
    process.env.NODE_ENV !== 'test'
  ) {
    console.warn('Heap not initialized');
  }
}

// These external functions match the Heap client side API at https://docs.heap.io/reference#identify
export function heapIdentify(userId: string) {
  withHeap((h) => h.identify(userId));
}

// Example: heap.addUserProperties({'Name': 'Alice Smith','Profession': 'Data Scientist'});
export function heapAddUserProperties(userProps: object) {
  withHeap((h) => h.addUserProperties(userProps));
}

// Example: heap.addEventProperties({'Logged In': 'true', 'Payment Plan': 'Free'});
export function heapAddEventProperties(eventProps: object) {
  withHeap((h) => h.addEventProperties(eventProps));
}

// Heap web installation https://docs.heap.io/docs/web
export function heapInit(appId: string) {
  window.heap = window.heap || [];
  window.heap.load = function (appid: any, t: any) {
    window.heap.appid = appid;
    window.heap.config = t = t || {};
    const r = t.forceSSL || 'https:' === document.location.protocol;
    const a = document.createElement('script');
    a.type = 'text/javascript';
    a.async = !0;
    a.src =
      (r ? 'https:' : 'http:') + '//cdn.heapanalytics.com/js/heap-' + appid + '.js';
    const n = document.getElementsByTagName('script')[0];
    if (n.parentNode) {
      n.parentNode.insertBefore(a, n);
    }
    for (
      let o = function (e: any) {
          return function () {
            window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        },
        p = [
          'addEventProperties',
          'addUserProperties',
          'clearEventProperties',
          'identify',
          'resetIdentity',
          'removeEventProperty',
          'setEventProperties',
          'track',
          'unsetEventProperty',
        ],
        c = 0;
      c < p.length;
      c++
    ) {
      window.heap[p[c]] = o(p[c]);
    }
  };

  window.heap.load(appId);
  heapInitialized = true;
}
