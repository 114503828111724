import * as React from 'react';
import { AppLink, AppLinkProps } from '../app-link';
import { Btn, Props as BtnProps } from '../btn';
import { MaterialIcon, MaterialIconName } from 'client/shared/components/base';
import _ from 'lodash';
import './styles.scss';

export type Props = Omit<BtnProps, 'action' | 'dontPreventDefault'> &
  Pick<AppLinkProps, 'to' | 'target'> & {
    readonly excludeIcon?: boolean;
    readonly linkClassName?: string;
  };

const baseClass = 'pn-btn-link';

export const BtnLink: React.FC<Props> = (p) => {
  // This regex removes any non alpha chars to convert the url into a HTML friendly string
  const linkId = `btn-link-${p.to.replace(/[^a-z|A-Z|0-9|-]/gi, '')}`;
  return (
    <div className={`${baseClass} ${p.linkClassName ?? ''}`}>
      <Btn
        {..._.omit(p, 'to', 'target')}
        action={() => {
          (
            document.querySelector(`#${linkId} a`) as HTMLAnchorElement | undefined
          )?.click();
        }}
        customPadding={p.customPadding}
      >
        <div className="align-items-center justify-content-center d-flex">
          {p.children}
          {p.target === '_blank' && !p.excludeIcon && (
            <span>
              <MaterialIcon
                className={`${baseClass}-icon`}
                icon={MaterialIconName.OPEN_IN_NEW}
              />
            </span>
          )}
        </div>
      </Btn>
      <div className="d-none" id={linkId}>
        <AppLink target={p.target} to={p.to}>
          {p.to}
        </AppLink>
      </div>
    </div>
  );
};
