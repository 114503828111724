import moment from 'moment';

// Date string formats
// Might want to import moment and isolate all date functions here too

// Date inputs, unless otherwise specified (currently standard)
export const TEXT_INPUT_DATE_FORMAT = 'dddd, MMMM D, YYYY';
// Comments
export const COMMENTS_DATE_FORMAT = 'MMM D, YYYY';
// End date tab
export const ENDDATE_DATE_FORMAT = 'dddd, MMMM Do, YYYY';
// Super admin dashboard
export const SUPER_DASHBOARD_DATE_FORMAT = 'MM-DD-YYYY hh:mm a';
// voting, equal to "MMMM D, YYYY"
export const VOTING_DATE_FORMAT = 'LL';
// for parsing date - most likely format for users to type
export const PARSE_DATE_FORMAT = 'MM/DD/YYYY';
// Used in the cover page of PDF report
export const PDF_REPORT_FORMAT = 'MM/DD/YYYY';
// New Tab Date Format
export const NEW_TAB_DATE_FORMAT = 'MM/DD/YY hh:mm:ss';
// For event start time
export const AM_PM_TIME = 'hh:mma';
// Date and time format, for scheduled events
export const DATE_AND_TIME_FORMAT = 'MMMM Do YYYY, h:mm a';
// Date format for default values on date form inputs
export const DATE_INPUT_FORMAT = 'YYYY-MM-DD';
// Format used only for years
export const YEAR_FORMAT = 'YYYY';
// Format used for short month and full year
export const MMM_YEAR_FORMAT = 'MMM YYYY';
// Format used for long month and full year
export const MMMM_YEAR_FORMAT = 'MMMM YYYY';
// DataWarehouse CRM Formats
export const DATA_WAREHOUSE_CRM_DATE_FORMAT = 'YYYY-MM-DD';
export const DATA_WAREHOUSE_CRM_TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';
// Format used for report requests
export const REPORT_REQUEST_DATE_FORMAT = 'YYYY/MM/DD';
// Close date format for publishing wizard and content overview pages (must be used with moment.tz)
export const CLOSE_DATE_FORMAT = 'hh:mma (z) [on] dddd, MMMM DD, YYYY';
// Format used for national benchmark results
export const NATIONAL_BENCHMARK_FORMAT = 'MMM D, YYYY';

// The date format given by L2
export const L2_BIRTHDATE_FORMAT = 'YYYY-MM-DD';
// The date format we save for respondent attributes
export const ATTRIBUTE_VALUE_DATE_FORMAT = 'M/D/YYYY';

export const EPOCH = new Date(0);
export const THE_FUTURE = new Date('3000/01/01');
export const ONE_HOUR_MS = 3600000;

export const MM_DD_YYYY_REGEX = /^\d(\d)?\/\d(\d)?\/\d\d\d\d$/;

export const isDateToday = (date: Date) => {
  return (
    moment(date).format(DATE_INPUT_FORMAT) ===
    moment(new Date()).format(DATE_INPUT_FORMAT)
  );
};
