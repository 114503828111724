export enum CtaLinkType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export interface CtaLinkProps {
  readonly link: string;
  readonly text: string;
  readonly type: CtaLinkType;
}
