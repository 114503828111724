import { Flavor } from './helpers/types';

export type IntervalKey = Flavor<number, 'interval-key'>;

export interface IClock {
  now(): Date;
  setInterval(f: Function, timeMillis: number): IntervalKey;
  clearInterval(key: IntervalKey): void;
  setTimeout(f: Function, timeMillis: number): void;
}

export function defaultClock(): IClock {
  return {
    setTimeout(f, timeMillis) {
      return setTimeout(f, timeMillis);
    },
    setInterval(f, timeMillis) {
      return setInterval(f, timeMillis);
    },
    clearInterval(k) {
      clearInterval(k);
    },
    now() {
      return new Date();
    },
  };
}
