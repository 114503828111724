import _ from 'lodash';
import qs from 'query-string';
import { enforceHttps } from './text-helpers';
import {
  BalancingActSimulationType,
  BenchmarkSurveyType,
  ClarificationType,
  EmailSettingType,
  KnownStoreKeys,
  QuestionSetStatus,
  QuestionSetType,
  ReportTabSettingsType,
  SavedVisualizationFilterStates,
} from './types';
import { Query } from 'express-serve-static-core';

interface UrlUtilsConfig {
  readonly siteUrl: string;
  readonly balancingActSiteUrl?: string;
  readonly includeBasePath: boolean;
  readonly forceHttps: boolean;
}

type PathUtils<T extends Record<string, Function>> = {
  readonly [P in keyof T]: {
    readonly path: T[P];
    readonly url: T[P];
  };
};

const basePath = '/n';
export const REDIRECT_TO = 'redirectTo';
const trackPath = '/admin/track';

export function buildUrlUtils({
  siteUrl,
  balancingActSiteUrl,
  includeBasePath,
  forceHttps,
}: UrlUtilsConfig) {
  const baseUrl = forceHttps ? enforceHttps(siteUrl) : siteUrl;
  function buildPathUtils<T extends Record<string, Function>>(t: T): PathUtils<T> {
    return _.mapValues(t, (v) => {
      return {
        path: function () {
          const basePart = includeBasePath ? basePath : '';
          return `${basePart}${v.apply(this, arguments)}`;
        },
        url: function () {
          return `${baseUrl}${basePath}${v.apply(this, arguments)}`;
        },
      };
    }) as any;
  }

  const CommonUrlUtils = {
    baseUrl,

    addQueryParams(rawUrl: string, newParams: Query) {
      const { url, query } = qs.parseUrl(rawUrl);
      return `${url}?${qs.stringify({ ...query, ...newParams })}`;
    },
    shortUrl(shortId: string) {
      return `${baseUrl}/${shortId}`;
    },
    pathToUrl(path: string) {
      return `${baseUrl}${basePath}${path}`;
    },
  };

  const AdminUtils = buildPathUtils({
    base: () => '/admin',

    participants: () => '/admin/participants',

    profile: () => '/admin/profile',

    settings: () => '/admin/settings',

    login: (a?: { readonly redirectTo: string }) =>
      `/admin/login${a?.redirectTo ? `?${REDIRECT_TO}=${a.redirectTo}` : ''}`,

    registration: () => '/admin/registration',

    registrationProfileSelection: (a: { readonly registrationId: string }) =>
      `/admin/registration/profile-selection/${a.registrationId}`,

    registrationTailorExperience: (a: { readonly registrationId: string }) =>
      `/admin/registration/tailor-experience/${a.registrationId}`,

    registrationExplanation: () => '/admin/registration-explanation',

    registrationExplanationClarification: (a: {
      readonly dest: ClarificationType;
    }) => `/admin/registration-explanation/clarification/${a.dest}`,

    registrationProfilePreview: (a: { readonly registrationId: string }) =>
      `/admin/registration/profile-preview/${a.registrationId}`,

    registrationFinish: (a: { readonly registrationId: string }) =>
      `/admin/registration/finish/${a.registrationId}`,

    emailVerification: () => '/admin/email-verification',

    rejected: () => '/admin/rejection',

    storeTiers: (a?: { readonly highlightKey: KnownStoreKeys }) =>
      `/admin/store${a?.highlightKey ? `?highlight=${a.highlightKey}` : ''}`,

    storeItem: (a: { readonly idOrKey: string }) => `/admin/store/item/${a.idOrKey}`,
    storePurchaseSuccess: (a: { readonly session_id: string }) =>
      `/admin/store/success?session_id=${a.session_id}`,

    library: (a?: { readonly query: string }) =>
      `/admin/library${a?.query ? `?query=${a.query}` : ''}`,

    benchmarkDesignProgram: () => '/admin/design-program/intro',

    benchmarkSurvey: (a: {
      readonly id: string;
      readonly tab?: ReportTabSettingsType;
    }) =>
      `/admin/benchmark-surveys/${a.id}${
        a.tab ? `?tab=${a.tab.toLowerCase()}` : ''
      }`,

    contentOverview: () => `/admin/content`,

    demoBenchmarkSurvey: (benchmarkType: BenchmarkSurveyType) =>
      `/admin/benchmark-surveys/demo/${benchmarkType}`,

    content: (a: { readonly id: string; readonly tab?: ReportTabSettingsType }) =>
      `/admin/content/${a.id}${a.tab ? `?tab=${a.tab.toLowerCase()}` : ''}`,

    contentSimulation: (a: {
      readonly type: BalancingActSimulationType;
      readonly isPb: boolean;
      readonly id: number;
    }) => {
      const type =
        a.type !== BalancingActSimulationType.RECEIPT && a.isPb
          ? 'prioritization'
          : Object.values(BalancingActSimulationType).find((t) => t === a.type);
      return `/admin/content/simulation/${type}/${a.id}`;
    },

    contentEditSimulation: (a: {
      readonly type: BalancingActSimulationType;
      readonly id: number;
    }) => {
      return `/admin/content/simulation/${a.type}/${a.id}/edit`;
    },

    contentSetEdit: (a: { readonly id: string }) => `/admin/content/${a.id}/edit`,

    pollEdit: (a: { readonly setId: string; readonly questionId: string }) =>
      `/admin/content/${a.setId}/questions/${a.questionId}/edit`,

    pollResults: (a: { readonly setId: string; readonly questionId: string }) =>
      `/admin/content/${a.setId}/question/${a.questionId}/results`,

    liveResult: (a: { readonly setId: string; readonly questionId: string }) =>
      `/admin/content/${a.setId}/question/${a.questionId}/live-results`,

    share: (a: { readonly id: string }) => `/admin/content/${a.id}/share`,

    contentSetSuperAdminSettings: (a: { readonly id: string }) =>
      `/admin/content/${a.id}/superadmin`,

    superadminDashboard: () => `/admin/superadmin/dashboard`,

    superadminPublisherEdit: (a: { readonly publisherId: string }) =>
      `/admin/superadmin/dashboard/publisher/${a.publisherId}/edit`,

    configuration: () => `/admin/superadmin/configuration`,

    benchmarkRequests: () => `/admin/superadmin/benchmark-request`,

    benchmarkRequestNew: () => `/admin/superadmin/benchmark-request/new`,

    benchmarkRequestEdit: (a: { readonly id: string }) =>
      `/admin/superadmin/benchmark-request/${a.id}`,

    questionSetCreate: () => '/admin/content/create',

    contentCreation: (a: {
      readonly type: 'set' | 'survey' | 'content-post' | 'live-event';
    }) => `/admin/content/create/${a.type}`,

    pollCreation: (a: { readonly setId: string }) =>
      `/admin/content/${a.setId}/create-question`,

    downloadableReport: (a: { readonly setId: string }) =>
      `/admin/content/${a.setId}/report`,

    printableView: (a: { readonly setId: string }) =>
      `/admin/content/${a.setId}/printable`,

    repostContent: (a: { readonly setId: string }) =>
      `/admin/content/${a.setId}/repost`,

    recommendedContent: (a: { readonly setId: string }) =>
      `/admin/content/${a.setId}/recommended`,
    publishingWizardIntro: (a: { readonly setId: string }) =>
      `/admin/content/${a.setId}/publishing-wizard/intro`,

    outreach: () => '/admin/outreach',

    stripeCheckout: (a: { readonly pubId: string }) => `/stripe/checkout/${a.pubId}`,

    stripePortal: (a: { readonly pubId: string }) =>
      `/stripe/customer-portal/${a.pubId}`,

    outcomeStep: (a: {
      readonly setId: string;
      readonly step: 'message' | 'content' | 'preview' | 'publish';
    }) => `/admin/content/${a.setId}/outcome-wizard/${a.step}`,

    dataDictionary: (a: {
      readonly setId: string;
      readonly reportRequestId: string;
    }) =>
      `/admin/superadmin/benchmark-request/${a.reportRequestId}/data-dictionary/${a.setId}`,

    resetPasswordRequest: () => '/admin/reset-password-request',

    resetPassword: () => '/admin/reset-password',

    learnMore: (variant: string) => `/admin/${variant}/learn-more`,

    trackOverview: () => `${trackPath}/overview`,
    trackDomains: () => `${trackPath}/domains`,
    trackSafety: () => `${trackPath}/safety`,
    trackEconomy: () => `${trackPath}/economy`,
    trackCommunityDesign: () => `${trackPath}/community-design`,
    trackHealthAndWellness: () => `${trackPath}/health-and-wellness`,
    trackInclusivityAndEngagement: () => `${trackPath}/inclusivity-and-engagement`,
    trackEducationArtsAndCulture: () => `${trackPath}/education-arts-and-culture`,
    trackMobility: () => `${trackPath}/mobility`,
    trackNaturalEnvironment: () => `${trackPath}/natural-environment`,
    trackParksAndRecreation: () => `${trackPath}/parks-and-recreation`,
    trackUtilities: () => `${trackPath}/utilities`,
    trackLearnMore: () => `${trackPath}/learn-more`,
    engageLearnMore: () => '/admin/engage/learn-more',
    assessLearnMore: () => '/admin/assess/learn-more',
    benchmarkSurveys: () => '/admin/benchmark-surveys',
    communityLivability: () => '/admin/community-livability',
    demoDisparity: () => '/admin/community-disparity',
    savedDataPoints: (a?: { tab?: SavedVisualizationFilterStates }) =>
      `/admin/saved-data-points${a?.tab ? `?tab=${a.tab.toLowerCase()}` : ''}`,
    ideaSubmissions: () => '/admin/idea-submissions',
    viewIdea: (id: string) => `/admin/idea-submissions/${id}`,
    askPolly: () => '/admin/ask-ai/polly',
    askGrace: () => '/admin/ask-ai/grants',
    tokenExpired: () => '/admin/expired',
    notFound: () => '/admin/not-found',
  });

  const RespondentUtils = buildPathUtils({
    account() {
      return '/res/account';
    },
    feed() {
      return '/res';
    },

    // ideas routes
    createIdea() {
      return '/res/idea/create';
    },

    viewIdea(id: string) {
      return `/res/idea/${id}`;
    },

    whatIsPolco() {
      return '/res/what-is-polco';
    },
    whatKindOfData() {
      return '/res/what-kind-of-data';
    },
    isThisSafe() {
      return '/res/is-this-safe';
    },

    // outcome related routes
    setOutcome(a: { readonly setIdOrSlug: string; readonly pubSlug: string }) {
      return `/res/outcome/${a.pubSlug}/${a.setIdOrSlug}/`;
    },
    setOverview(a: { readonly setIdOrSlug: string; readonly pubSlug: string }) {
      return `/res/overview/${a.pubSlug}/${a.setIdOrSlug}/`;
    },
    outcomeReport(a: {
      readonly setIdOrSlug: string;
      readonly pubSlug: string;
      readonly reportId: string;
    }) {
      return `/res/overview/${a.pubSlug}/${a.setIdOrSlug}/report/${a.reportId}`;
    },

    // polco live related routes
    polcoLiveThankYou(a: {
      readonly setIdOrSlug: string;
      readonly pubSlug: string;
    }) {
      return `/res/vote/${a.pubSlug}/${a.setIdOrSlug}/thank-you`;
    },
    polcoLiveWaiting(a: { readonly setIdOrSlug: string; readonly pubSlug: string }) {
      return `/res/live-event/${a.pubSlug}/${a.setIdOrSlug}/waiting`;
    },

    subscriptions() {
      return '/res/subscriptions';
    },

    set(a: {
      readonly setIdOrSlug: string;
      readonly pubSlug: string;
      readonly setType: QuestionSetType;
    }) {
      switch (a.setType) {
        case QuestionSetType.POLCO_LIVE:
          return `/res/live-event/${a.pubSlug}/${a.setIdOrSlug}`;
        case QuestionSetType.SET:
        case QuestionSetType.SURVEY:
        case QuestionSetType.CONTENT_POST:
          return `/res/vote/${a.pubSlug}/${a.setIdOrSlug}`;
      }
    },
    pubProfile(a: { readonly slug: string }) {
      return `/res/profile/${a.slug}`;
    },
    question(a: {
      readonly questionId: string;
      readonly setIdOrSlug: string;
      readonly pubSlug: string;
      readonly setType: QuestionSetType.SET | QuestionSetType.POLCO_LIVE;
      readonly fromOverview?: boolean;
    }) {
      switch (a.setType) {
        case QuestionSetType.POLCO_LIVE:
          return `/res/live-event/${a.pubSlug}/${a.setIdOrSlug}/question/${
            a.questionId
          }${a.fromOverview ? '?from=overview' : ''}`;
        case QuestionSetType.SET:
          return `/res/vote/${a.pubSlug}/${a.setIdOrSlug}/question/${a.questionId}${
            a.fromOverview ? '?from=overview' : ''
          }`;
      }
    },

    questionChoice(a: {
      readonly choiceId: string;
      readonly questionId: string;
      readonly setIdOrSlug: string;
      readonly pubSlug: string;
    }) {
      return `/res/vote/${a.pubSlug}/${a.setIdOrSlug}/question/${a.questionId}?selection=${a.choiceId}`;
    },

    signup() {
      return `/res/signup`;
    },

    login() {
      return `/res/login`;
    },

    resetPasswordRequest() {
      return '/res/reset-password-request';
    },

    resetPassword() {
      return '/res/reset-password';
    },

    badShortUrl(a: { readonly shortId: string }) {
      return `/res/short-url-help/${a.shortId}`;
    },

    notFound() {
      return '/res/not-found';
    },

    balancingActSimulation(a: {
      readonly simulationId: string;
      readonly isTaxReceipt?: boolean;
    }) {
      if (a.isTaxReceipt) {
        return `/res/balancing-act-tax-receipt/${a.simulationId}`;
      }
      return `/res/balancing-act-simulation/${a.simulationId}`;
    },
  });

  const EmailPreferenceUtils = buildPathUtils({
    emailPreferences(a: { readonly emailPreferenceToken: string }) {
      return `/email-preferences/${a.emailPreferenceToken}`;
    },

    oneClickUnsubscribe(a: {
      readonly emailPreferenceToken: string;
      readonly unsubscribeType: EmailSettingType;
    }) {
      return `/unsubscribe/${a.unsubscribeType}/${a.emailPreferenceToken}`;
    },

    unsubscribeFromOutreach(a: { readonly emailListEntryId: string }) {
      return `/email-preferences/email-list-unsubscribe/${a.emailListEntryId}`;
    },
    unsubscribeFromAdminShare(a: { readonly sharedEmailId: string }) {
      return `/email-preferences/admin-shared-email-unsubscribe/${a.sharedEmailId}`;
    },
  });

  const EmbedUtils = buildPathUtils({
    question(a: {
      readonly questionId: string;
      readonly setIdOrSlug: string;
      readonly pubSlug: string;
    }) {
      return `/embed/vote/${a.pubSlug}/${a.setIdOrSlug}/question/${a.questionId}`;
    },

    signup() {
      return `/embed/signup`;
    },

    login() {
      return `/embed/login`;
    },
    visualization(a: { readonly visualizationId: string }) {
      return `/embed/visualization/${a.visualizationId}`;
    },
    contentPost(a: { contentPostSlug: string; pubSlug: string }) {
      return `/embed/post/${a.pubSlug}/${a.contentPostSlug}`;
    },
  });

  const BalancingActUtils = {
    balancingActUrl: (simulation: {
      id: number;
      isPrioritization: boolean;
      status: QuestionSetStatus;
      type: BalancingActSimulationType;
    }) => {
      if (simulation.isPrioritization) {
        if (simulation.status === QuestionSetStatus.NOT_STARTED) {
          return `${balancingActSiteUrl}/pb/${simulation.id}`;
        } else {
          return `${balancingActSiteUrl}/analytics/${simulation.id}`;
        }
      } else if (simulation.type === BalancingActSimulationType.RECEIPT) {
        return `${balancingActSiteUrl}/receipts/${simulation.id}`;
      } else {
        return `${balancingActSiteUrl}/simulations/${simulation.id}`;
      }
    },
    editBalancingActSimulation: (id: number) => {
      return `${balancingActSiteUrl}/simulations/${id}`;
    },
    editBalancingActReceiptSimulation: (id: number) => {
      return `${balancingActSiteUrl}/receipts/${id}`;
    },
  };

  return {
    common: CommonUrlUtils,
    respondent: RespondentUtils,
    admin: AdminUtils,
    embed: EmbedUtils,
    emailPreferences: EmailPreferenceUtils,
    balancingAct: BalancingActUtils,
  };
}
export default buildUrlUtils;
