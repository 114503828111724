import { PermissionType } from '@polco-us/types';
import { QuestionSetType } from '.';

export enum QuestionSetCommonPermissionTypeKeys {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  COPY = 'COPY',
  PUBLISH = 'PUBLISH',
  VIEW_RESULTS = 'VIEW_RESULTS',
  VIEW = 'VIEW',
  CREATE_INNER_POLL = 'CREATE_INNER_POLL',
  DELETE_INNER_POLL = 'DELETE_INNER_POLL',
  EDIT_INNER_POLL = 'EDIT_INNER_POLL',
  SHARE_INNER_POLL = 'SHARE_INNER_POLL',
  SHARE_AND_MANAGE_ACCESS = 'SHARE_AND_MANAGE_ACCESS',
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
  PRINT_PREVIEW = 'PRINT_PREVIEW',
  VIEW_RESPONDENT_VIEW = 'VIEW_RESPONDENT_VIEW',
  PREPARE_OUTCOME = 'PREPARE_OUTCOME',
}

export enum QuestionSetStatus {
  NOT_STARTED = 'NOT_STARTED',
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
  SOFT_DELETED = 'SOFT_DELETED',
  HISTORIC_RECORD = 'HISTORIC_RECORD',
}

export type QuestionSetActions = {
  readonly [actionKey in Extract<
    QuestionSetCommonPermissionTypeKeys,
    | QuestionSetCommonPermissionTypeKeys.EDIT
    | QuestionSetCommonPermissionTypeKeys.COPY
    | QuestionSetCommonPermissionTypeKeys.DELETE
    | QuestionSetCommonPermissionTypeKeys.PUBLISH
    | QuestionSetCommonPermissionTypeKeys.CREATE_INNER_POLL
    | QuestionSetCommonPermissionTypeKeys.EDIT_INNER_POLL
    | QuestionSetCommonPermissionTypeKeys.DELETE_INNER_POLL
    | QuestionSetCommonPermissionTypeKeys.SHARE_INNER_POLL
    | QuestionSetCommonPermissionTypeKeys.SHARE_AND_MANAGE_ACCESS
    | QuestionSetCommonPermissionTypeKeys.DOWNLOAD_REPORT
    | QuestionSetCommonPermissionTypeKeys.PRINT_PREVIEW
    | QuestionSetCommonPermissionTypeKeys.VIEW_RESPONDENT_VIEW
    | QuestionSetCommonPermissionTypeKeys.PREPARE_OUTCOME
  >]: boolean;
};
export type QuestionSetFilterTypes =
  | 'ALL'
  | Extract<
      QuestionSetStatus,
      | QuestionSetStatus.NOT_STARTED
      | QuestionSetStatus.IN_PROGRESS
      | QuestionSetStatus.CLOSED
    >;

export type QuestionSetCommonPermissionType = {
  readonly [questionSetKey in QuestionSetType]: {
    readonly [actionKey in Exclude<
      QuestionSetCommonPermissionTypeKeys,
      | QuestionSetCommonPermissionTypeKeys.VIEW
      | QuestionSetCommonPermissionTypeKeys.CREATE_INNER_POLL
      | QuestionSetCommonPermissionTypeKeys.EDIT_INNER_POLL
      | QuestionSetCommonPermissionTypeKeys.DELETE_INNER_POLL
      | QuestionSetCommonPermissionTypeKeys.SHARE_INNER_POLL
    >]: PermissionType;
  } & {
    readonly [viewKey in Extract<
      QuestionSetCommonPermissionTypeKeys,
      QuestionSetCommonPermissionTypeKeys.VIEW
    >]: {
      readonly [setStatus in QuestionSetStatus]: readonly PermissionType[];
    };
  } & {
    readonly [optionalActionKey in Extract<
      QuestionSetCommonPermissionTypeKeys,
      | QuestionSetCommonPermissionTypeKeys.CREATE_INNER_POLL
      | QuestionSetCommonPermissionTypeKeys.EDIT_INNER_POLL
      | QuestionSetCommonPermissionTypeKeys.DELETE_INNER_POLL
      | QuestionSetCommonPermissionTypeKeys.SHARE_INNER_POLL
    >]?: PermissionType;
  };
};
