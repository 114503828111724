import * as React from 'react';
import './styles.scss';
import { ClickableDiv } from '../base';

export interface Props {
  readonly envName: string;
}

export const EnvBanner: React.FC<Props> = (p) => {
  const baseClass = 'pn-env-banner';

  const [show, setShow] = React.useState(true);

  return show ? (
    <ClickableDiv
      action={() => setShow(false)}
      className={`${baseClass} text-center text-white`}
    >
      This is a <b>{p.envName}</b> environment
    </ClickableDiv>
  ) : (
    <></>
  );
};
