import { QuestionSetAttributeTypes } from './reporting';

export enum LanguageKey {
  ARABIC = 'arabic',
  CHINESE_SIMPLIFIED = 'chinese-simplified',
  CHINESE_TRADITIONAL = 'chinese-traditional',
  HAITIAN_CREOLE = 'haitian-creole', // Haitian Creole
  PORTUGUESE = 'portuguese',
  CAPE_VERDEAN_CREOLE = 'cape-verdean-creole',
  ENGLISH = 'english',
  FRENCH = 'french',
  JAPANESE = 'japanese',
  KOREAN = 'korean',
  MARSHALLESE = 'marshallese',
  OROMO = 'oromo',
  RUSSIAN = 'russian',
  SPANISH = 'spanish',
  TAGALOG = 'tagalog',
  VIETNAMESE = 'vietnamese',
  HINDI = 'hindi',
  SOMALI = 'somali',
  AMHARIC = 'amharic',
  FARSI = 'farsi',
  HMONG = 'hmong',
  NEPALI = 'nepali',
  BENGALI = 'bengali',
  YIDDISH = 'yiddish',
  POLISH = 'polish',
  SWAHILI = 'swahili',
  GERMAN = 'german',
  UKRAINIAN = 'ukrainian',
  GUJARATI = 'gujarati',
}

export interface Language {
  readonly id: string;
  readonly key: LanguageKey;
  readonly name: string;
}

export const DEFAULT_LANGUAGE = LanguageKey.ENGLISH;
export const LANGUAGE_PARAM = QuestionSetAttributeTypes.LANGUAGE;
