export type RGBColor = readonly [number, number, number];
export function rawToHex(raw: RGBColor) {
  return `#${raw.map((part) => part.toString(16).padStart(2, '0')).join('')}`;
}
export function rawToRgb(raw: RGBColor, opacity?: number) {
  return `rgb(${raw.join(',')}${opacity ? `,${opacity}` : ''})`;
}
function shift(input: readonly RGBColor[], amount: number) {
  return [...input.slice(amount), ...input.slice(0, amount)];
}
function colorsFromRaw(raw: readonly RGBColor[]) {
  return {
    raw: raw,
    hex: raw.map(rawToHex),
    rgb: raw.map(rawToRgb),
  };
}

export const COLORS_JUNGLE_L_HEX = '#3a8172';
export const COLORS_JUNGLE_XD_HEX = '#132D29';

export const COLORS_GRAY_60_HEX = '#303330';
export const COLORS_GRAY_50_HEX = '#464b47';
export const COLORS_GRAY_40_HEX = '#656b65';
export const COLORS_GRAY_30_HEX = '#90968f';
export const COLORS_GRAY_20_HEX = '#c6cbc8';
export const COLORS_GRAY_10_HEX = '#eaeceb';
export const COLORS_GRAY_6_HEX = '#f2f2f2';
export const COLORS_GRAY_5_HEX = '#f7f7f7';

export const COLORS_BRAND_D: RGBColor = [106, 134, 57];
export const COLORS_BRAND_D_HEX = rawToHex(COLORS_BRAND_D);

export const COLORS_BRAND_XD: RGBColor = [60, 76, 33];
export const COLORS_BRAND_XD_HEX = rawToHex(COLORS_BRAND_XD);

export const COLORS_POLCO_GREEN: RGBColor = [151, 187, 87];
export const COLORS_POLCO_GREEN_HEX = rawToHex(COLORS_POLCO_GREEN);

export const COLORS_POLCO_GREEN_L: RGBColor = [199, 216, 160];
export const COLORS_POLCO_GREEN_L_HEX = rawToHex(COLORS_POLCO_GREEN_L);

export const COLORS_LIME: RGBColor = [14, 173, 0];
export const COLORS_LIME_HEX = rawToHex(COLORS_LIME);

export const COLORS_CANARY: RGBColor = [247, 213, 79];
export const COLORS_CANARY_HEX = rawToHex(COLORS_CANARY);

export const COLORS_CANARY_D: RGBColor = [216, 175, 41];
export const COLORS_CANARY_D_HEX = rawToHex(COLORS_CANARY_D);

export const COLORS_NRC_GOLD_D: RGBColor = [142, 106, 50];
export const COLORS_NRC_GOLD_D_HEX = rawToHex(COLORS_NRC_GOLD_D);

export const COLORS_JUNGLE: RGBColor = [35, 80, 72];
export const COLORS_JUNGLE_HEX = rawToHex(COLORS_JUNGLE);

export const COLORS_JUNGLE_D: RGBColor = [28, 60, 53];
export const COLORS_JUNGLE_D_HEX = rawToHex(COLORS_JUNGLE_D);

export const COLORS_SKY: RGBColor = [105, 168, 183];
export const COLORS_SKY_HEX = rawToHex(COLORS_SKY);

export const COLORS_SKY_D: RGBColor = [66, 121, 135];
export const COLORS_SKY_D_HEX = rawToHex(COLORS_SKY_D);

export const COLORS_ROSE: RGBColor = [222, 146, 189];
export const COLORS_ROSE_HEX = rawToHex(COLORS_ROSE);

export const COLORS_ROSE_D: RGBColor = [161, 49, 114];
export const COLORS_ROSE_D_HEX = rawToHex(COLORS_ROSE_D);

export const COLORS_VALENCIA: RGBColor = [226, 111, 3];
export const COLORS_VALENCIA_HEX = rawToHex(COLORS_VALENCIA);

export const COLORS_VALENCIA_D: RGBColor = [113, 55, 1];
export const COLORS_VALENCIA_D_HEX = rawToHex(COLORS_VALENCIA_D);

export const COLORS_VALENCIA_XL: RGBColor = [248, 219, 192];
export const COLORS_VALENCIA_XL_HEX = rawToHex(COLORS_VALENCIA_XL);

export const COLORS_AQUA: RGBColor = [31, 163, 161];
export const COLORS_AQUA_HEX = rawToHex(COLORS_AQUA);

export const COLORS_LIBERTY: RGBColor = [78, 97, 186];
export const COLORS_LIBERTY_HEX = rawToHex(COLORS_LIBERTY);

export const COLORS_LIBERTY_D: RGBColor = [41, 51, 96];
export const COLORS_LIBERTY_D_HEX = rawToHex(COLORS_LIBERTY_D);

export const COLORS_LIBERTY_L: RGBColor = [210, 214, 237];
export const COLORS_LIBERTY_L_HEX = rawToHex(COLORS_LIBERTY_L);

export const COLORS_ROYAL_PURPLE: RGBColor = [125, 88, 187];
export const COLORS_ROYAL_PURPLE_HEX = rawToHex(COLORS_ROYAL_PURPLE);

export const COLORS_ROYAL_PURPLE_D: RGBColor = [35, 26, 51];
export const COLORS_ROYAL_PURPLE_D_HEX = rawToHex(COLORS_ROYAL_PURPLE_D);

export const COLORS_ROYAL_PURPLE_L: RGBColor = [221, 212, 237];
export const COLORS_ROYAL_PURPLE_L_HEX = rawToHex(COLORS_ROYAL_PURPLE_L);

const coreRawColors: readonly RGBColor[] = [
  COLORS_CANARY,
  COLORS_NRC_GOLD_D,
  COLORS_POLCO_GREEN,
  COLORS_JUNGLE,
  COLORS_SKY,
  COLORS_ROSE,
  COLORS_ROSE_D,
];

const coreRawColorsAccessible: readonly RGBColor[] = [
  COLORS_BRAND_XD,
  COLORS_LIBERTY_D,
  COLORS_ROSE_D,
  COLORS_JUNGLE_D,
  COLORS_VALENCIA_D,
  COLORS_SKY_D,
  COLORS_ROYAL_PURPLE_D,
];

export enum ColorContext {
  BAR_CHART = 'BAR_CHART',
  PIE_CHART = 'PIE_CHART',
  MAP = 'MAP',
  LOADING_BARS = 'LOADING_BARS',
  LOADING_BARS_ACCESSIBLE = 'LOADING_BARS_ACCESSIBLE',
  WORD_CLOUD = 'WORD_CLOUD',
  DEFAULT = 'DEFAULT',
}

// These colors are all based on the same "core" colors,
// but the order is shifted around to allow them to look
// optimal in different contexts. Bar charts tend to have
// most data in the middle of them, so we want our best
// colors in the middle. This isn't the case for maps or
// pie charts though. So we are giving each type its own
// special color ordering.
const barChartRawColors = shift(coreRawColors, 0);
const pieChartRawColors = [
  COLORS_AQUA,
  COLORS_ROYAL_PURPLE,
  COLORS_POLCO_GREEN,
  COLORS_CANARY,
  COLORS_ROSE_D,
  COLORS_VALENCIA,
  COLORS_SKY_D,
  COLORS_LIBERTY,
];
const mapRawColors = shift(coreRawColors, 2);
export const loadingBarRawColors = shift(coreRawColors, 2);
const wordCloudRawColors = shift(coreRawColors, 4);

export const colors = {
  [ColorContext.BAR_CHART]: colorsFromRaw(barChartRawColors),
  [ColorContext.PIE_CHART]: colorsFromRaw(pieChartRawColors),
  [ColorContext.MAP]: colorsFromRaw(mapRawColors),
  [ColorContext.LOADING_BARS]: colorsFromRaw(loadingBarRawColors),
  [ColorContext.LOADING_BARS_ACCESSIBLE]: colorsFromRaw(coreRawColorsAccessible),
  [ColorContext.WORD_CLOUD]: colorsFromRaw(wordCloudRawColors),
  [ColorContext.DEFAULT]: colorsFromRaw(coreRawColors),
};

export const loadingBarHexColors = colors[ColorContext.LOADING_BARS].hex;
export const loadingBarHexColorsAccessible =
  colors[ColorContext.LOADING_BARS_ACCESSIBLE].hex;
export const defaultHexColors = colors[ColorContext.DEFAULT].hex;

// max 7 choices
export const colorPalette = {
  Aqua1: '#79C8C7',
  Aqua2: '#4CB5B4',
  Aqua3: COLORS_AQUA_HEX,
  Aqua4: '#198281',
  Aqua5: '#167271',
  Aqua6: '#136261',
  Aqua7: '#0C4140',
  Aqua8: '#093130',
};

const stackColors: Record<number, readonly string[] | undefined> = {
  2: [colorPalette.Aqua8, colorPalette.Aqua1],
  3: [colorPalette.Aqua8, colorPalette.Aqua5, colorPalette.Aqua1],
  4: [
    colorPalette.Aqua8,
    colorPalette.Aqua5,
    colorPalette.Aqua3,
    colorPalette.Aqua1,
  ],
  5: [
    colorPalette.Aqua8,
    colorPalette.Aqua6,
    colorPalette.Aqua4,
    colorPalette.Aqua3,
    colorPalette.Aqua1,
  ],
  6: [
    colorPalette.Aqua8,
    colorPalette.Aqua7,
    colorPalette.Aqua6,
    colorPalette.Aqua4,
    colorPalette.Aqua3,
    colorPalette.Aqua1,
  ],
  7: [
    colorPalette.Aqua8,
    colorPalette.Aqua7,
    colorPalette.Aqua6,
    colorPalette.Aqua4,
    colorPalette.Aqua3,
    colorPalette.Aqua2,
    colorPalette.Aqua1,
  ],
};

export const grayPalette = {
  Gray4: '#E0E0E0',
  Gray5: '#BDBDBD',
  Gray20: '#C6CBC8',
  Gray40: '#656B65',
};

const stackGrays: Record<number, readonly string[] | undefined> = {
  1: [grayPalette.Gray4],
  2: [grayPalette.Gray4, grayPalette.Gray40],
};

export function getStackColor(choicesCount: number, choiceIndex: number): string {
  const color = stackColors[choicesCount]?.[choiceIndex];
  return color ?? grayPalette.Gray4;
}

export function getStackGray(choicesCount: number, choiceIndex: number): string {
  const color = stackGrays[choicesCount]?.[choiceIndex];
  return color ?? grayPalette.Gray4;
}

export enum BenchmarkTypeThemeColors {
  SKY = 'sky',
  LIBERTY = 'liberty',
}

export const benchmarkThemeColors: Record<BenchmarkTypeThemeColors, string> = {
  [BenchmarkTypeThemeColors.SKY]: COLORS_SKY_HEX,
  [BenchmarkTypeThemeColors.LIBERTY]: COLORS_LIBERTY_HEX,
};

export const indexScoreColors = {
  valencia: { hex: COLORS_VALENCIA_HEX, text: 'text-valencia' },
  canaryD: { hex: COLORS_CANARY_D_HEX, text: 'text-canary-d' },
  gray60: { hex: COLORS_GRAY_60_HEX, text: 'text-gray-60' },
  aqua: { hex: colorPalette.Aqua4, text: 'text-aqua' },
  lime: { hex: COLORS_LIME_HEX, text: 'text-lime' },
};
