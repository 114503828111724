import gql from 'graphql-tag';
import {
  MutationProps,
  MutationFunctionReadonly,
} from 'client/shared/containers/mutation';
import { DataProxy } from '@apollo/client';
import { ExecutionResult } from 'graphql';

export type MutationItem<D, V> = Omit<MutationProps<D, V>, 'children'>;

export async function mutate<
  TMutationArgs,
  TMutationResult,
  TResultData extends keyof TMutationResult
>(
  mutateFn: MutationFunctionReadonly<TMutationResult, TMutationArgs>,
  data: TMutationArgs,
  resData: TResultData
): Promise<TMutationResult[TResultData] | null> {
  const res = (await mutateFn({
    variables: data,
  })) as ExecutionResult<TMutationResult>;
  if (res.data) {
    return res.data[resData];
  } else {
    return null;
  }
}

export function mutationItem<TData, TVariables>(
  p: MutationItem<TData, TVariables>
): MutationItem<TData, TVariables> {
  return p;
}

const currentUserFragment = gql`
  fragment currentUser on CurrentUser {
    id
    __typename
  }
`;

// if you try to write to something that isn't there, Apollo gets real mad
export function hasCurrentUser(f: DataProxy) {
  const q: object | null = f.readFragment({
    id: 'CurrentUser:CURRENT_USER',
    fragment: currentUserFragment,
  });
  return !!q;
}
