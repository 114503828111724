export type TriggerHandler<T extends object> = (data: T) => void;

export interface TriggerSubscription {
  readonly key: string;
  readonly keepAfterTrigger: boolean;
  readonly handler: TriggerHandler<any>;
}

let _triggerSubscriptions: readonly TriggerSubscription[] = [];

export namespace Triggers {
  export function on(key: string, handler: TriggerHandler<any>) {
    _triggerSubscriptions = [
      ..._triggerSubscriptions,
      { key, handler, keepAfterTrigger: true },
    ];
  }
  export function once(key: string, handler: TriggerHandler<any>) {
    _triggerSubscriptions = [
      ..._triggerSubscriptions,
      { key, handler, keepAfterTrigger: false },
    ];
  }
  export function off(key: string, handler: TriggerHandler<any>) {
    _triggerSubscriptions = _triggerSubscriptions.filter(
      (s) => s.key !== key && s.handler !== handler
    );
  }
  export function trigger(key: string, data: {}) {
    const remove: TriggerSubscription[] = [];
    _triggerSubscriptions
      .filter((s) => s.key === key)
      .forEach((s) => {
        s.handler(data);
        if (!s.keepAfterTrigger) {
          remove.push(s);
        }
      });
    _triggerSubscriptions = _triggerSubscriptions.filter((s) => !remove.includes(s));
  }
}

(window as any)._pnTrigger = Triggers.trigger;
