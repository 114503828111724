import { Case, unionOfEnum } from 'core';

export enum ReportTabSettingsType {
  // Tableau Tabs
  TABLEAU = 'TABLEAU',
  // HTML Tabs
  HTML = 'HTML',
  COMMUNITY_READINESS = 'COMMUNITY_READINESS',
  COMMUNITY_NEEDS = 'COMMUNITY_NEEDS',
  ECONOMIC_CONTRIBUTION = 'ECONOMIC_CONTRIBUTION',
  INTRODUCTION = 'INTRODUCTION',
  KEY_FINDINGS = 'KEY_FINDINGS',
  METHODS = 'METHODS',
  // Supported tabs
  PARTICIPATION = 'PARTICIPATION',
  RESULTS = 'RESULTS',
  ADVANCED_RESULTS = 'ADVANCED_RESULTS', // This will probably be removed eventually (the long term plan has advanced results replaced by crosstabs)
  REPOSTS = 'REPOSTS',
  OVERVIEW = 'OVERVIEW',
  TOPICS = 'TOPICS',
  QUALITY_IMPORTANCE = 'QUALITY_IMPORTANCE',
  CROSSTABS = 'CROSSTABS',
  // Understanding the Report tab
  UNDERSTANDING_THE_REPORT = 'UNDERSTANDING_THE_REPORT',
  // Full trends
  FULL_TRENDS = 'FULL_TRENDS',
}

export type CustomReportTabTypes =
  | ReportTabSettingsType.HTML
  | ReportTabSettingsType.COMMUNITY_READINESS
  | ReportTabSettingsType.COMMUNITY_NEEDS
  | ReportTabSettingsType.ECONOMIC_CONTRIBUTION
  | ReportTabSettingsType.INTRODUCTION
  | ReportTabSettingsType.KEY_FINDINGS
  | ReportTabSettingsType.METHODS
  | ReportTabSettingsType.TABLEAU;

export const CUSTOM_REPORT_TABS = [
  ReportTabSettingsType.HTML,
  ReportTabSettingsType.TABLEAU,
  ReportTabSettingsType.COMMUNITY_READINESS,
  ReportTabSettingsType.COMMUNITY_NEEDS,
  ReportTabSettingsType.ECONOMIC_CONTRIBUTION,
  ReportTabSettingsType.INTRODUCTION,
  ReportTabSettingsType.KEY_FINDINGS,
  ReportTabSettingsType.METHODS,
];

export type ReportTabContent =
  | Case<ReportTabSettingsType.HTML, { readonly raw_html: string }>
  | Case<ReportTabSettingsType.COMMUNITY_READINESS, { readonly raw_html: string }>
  | Case<ReportTabSettingsType.COMMUNITY_NEEDS, { readonly raw_html: string }>
  | Case<ReportTabSettingsType.ECONOMIC_CONTRIBUTION, { readonly raw_html: string }>
  | Case<ReportTabSettingsType.INTRODUCTION, { readonly raw_html: string }>
  | Case<ReportTabSettingsType.KEY_FINDINGS, { readonly raw_html: string }>
  | Case<ReportTabSettingsType.METHODS, { readonly raw_html: string }>
  | Case<ReportTabSettingsType.TABLEAU, { readonly tableau_url: string }>
  | Case<ReportTabSettingsType.PARTICIPATION>
  | Case<ReportTabSettingsType.RESULTS>
  | Case<ReportTabSettingsType.ADVANCED_RESULTS>
  | Case<ReportTabSettingsType.REPOSTS>
  | Case<ReportTabSettingsType.OVERVIEW>
  | Case<ReportTabSettingsType.TOPICS>
  | Case<ReportTabSettingsType.QUALITY_IMPORTANCE>
  | Case<ReportTabSettingsType.CROSSTABS>
  | Case<ReportTabSettingsType.UNDERSTANDING_THE_REPORT>
  | Case<ReportTabSettingsType.FULL_TRENDS>;

export const ReportTabContent = unionOfEnum(ReportTabSettingsType, {
  ...ReportTabSettingsType,
}).andType<ReportTabContent>();

export type CustomReportTabContent = Extract<
  ReportTabContent,
  { readonly type: CustomReportTabTypes }
>;

export function isCustomReportTabContent(
  content: ReportTabContent
): content is CustomReportTabContent {
  return CUSTOM_REPORT_TABS.includes(content.type);
}

// These tab types are custom/non-automated tab types that are created by super admins
// They are often used for custom reports or off platform tableau reports
export function isHTMLOrTableauTab(type: ReportTabSettingsType): boolean {
  return (
    type === ReportTabSettingsType.HTML || type === ReportTabSettingsType.TABLEAU
  );
}

export function isHTMLTabType(tab: ReportTabSettingsType) {
  switch (tab) {
    case ReportTabSettingsType.HTML:
    case ReportTabSettingsType.KEY_FINDINGS:
    case ReportTabSettingsType.COMMUNITY_NEEDS:
    case ReportTabSettingsType.COMMUNITY_READINESS:
    case ReportTabSettingsType.INTRODUCTION:
    case ReportTabSettingsType.METHODS:
    case ReportTabSettingsType.ECONOMIC_CONTRIBUTION:
      return true;
    default:
      return false;
  }
}

export function isHTMLTabContent(
  content: ReportTabContent
): content is
  | Extract<ReportTabContent, { readonly type: ReportTabSettingsType.HTML }>
  | Extract<ReportTabContent, { readonly type: ReportTabSettingsType.KEY_FINDINGS }>
  | Extract<ReportTabContent, { readonly type: ReportTabSettingsType.METHODS }>
  | Extract<
      ReportTabContent,
      { readonly type: ReportTabSettingsType.ECONOMIC_CONTRIBUTION }
    >
  | Extract<
      ReportTabContent,
      { readonly type: ReportTabSettingsType.COMMUNITY_NEEDS }
    >
  | Extract<
      ReportTabContent,
      { readonly type: ReportTabSettingsType.COMMUNITY_READINESS }
    >
  | Extract<
      ReportTabContent,
      { readonly type: ReportTabSettingsType.INTRODUCTION }
    > {
  return isHTMLTabType(content.type);
}

export function getTypedReportTabContent(
  type: ReportTabSettingsType,
  rawHtml: string,
  tableauUrl: string
) {
  switch (type) {
    case ReportTabSettingsType.COMMUNITY_NEEDS:
      return ReportTabContent.COMMUNITY_NEEDS({ raw_html: rawHtml });
    case ReportTabSettingsType.COMMUNITY_READINESS:
      return ReportTabContent.COMMUNITY_READINESS({ raw_html: rawHtml });
    case ReportTabSettingsType.INTRODUCTION:
      return ReportTabContent.INTRODUCTION({ raw_html: rawHtml });
    case ReportTabSettingsType.KEY_FINDINGS:
      return ReportTabContent.KEY_FINDINGS({ raw_html: rawHtml });
    case ReportTabSettingsType.METHODS:
      return ReportTabContent.METHODS({ raw_html: rawHtml });
    case ReportTabSettingsType.ECONOMIC_CONTRIBUTION:
      return ReportTabContent.ECONOMIC_CONTRIBUTION({ raw_html: rawHtml });
    case ReportTabSettingsType.HTML:
      return ReportTabContent.HTML({ raw_html: rawHtml });
    default:
      return ReportTabContent.TABLEAU({ tableau_url: tableauUrl });
  }
}

export enum SubscriberStatType {
  AGE = 'AGE',
  GENDER = 'GENDER',
  RACE = 'RACE',
  LOCATION = 'LOCATION',
}

export enum ResponseStatType {
  AGE = 'AGE',
  GENDER = 'GENDER',
  RACE = 'RACE',
  LOCATION = 'LOCATION',
  VERIFIED_RESPONSES = 'VERIFIED_RESPONSES',
  LANGUAGE = 'LANGUAGE',
  SAMPLE = 'SAMPLE',
}

export enum ReportTabSettingsTypeName {
  // Tableau Tabs
  TABLEAU = 'Tableau',
  // HTML Tabs
  HTML = 'HTML',
  COMMUNITY_READINESS = 'Community Readiness',
  COMMUNITY_NEEDS = 'Community Needs',
  ECONOMIC_CONTRIBUTION = 'Economic Contribution',
  INTRODUCTION = 'Introduction',
  KEY_FINDINGS = 'Key Findings',
  METHODS = 'Methods',
  // Supported tabs
  PARTICIPATION = 'Participation',
  RESULTS = 'Results',
  ADVANCED_RESULTS = 'Advanced Results', // This will probably be removed eventually (the long term plan has advanced results replaced by crosstabs)
  REPOSTS = 'Reposts',
  OVERVIEW = 'Overview',
  TOPICS = 'Community Livability Topics',
  QUALITY_IMPORTANCE = 'Quality Importance',
  CROSSTABS = 'Crosstabs',
  // Understanding the Report tab
  UNDERSTANDING_THE_REPORT = 'Understanding the Report',
}

export function getReportTabSettingsTypeName(
  type: ReportTabSettingsType
): ReportTabSettingsTypeName {
  return ReportTabSettingsTypeName[
    ReportTabSettingsType[type] as keyof typeof ReportTabSettingsTypeName
  ];
}

/**
 * This enum needs to be kept in sync with the
 * AGE_BRACKET question_choice_set. It is gross,
 * but necessary because we are "joining" birthdate
 * and age_bracket in code, so we need to have this
 * standardized
 */
export enum AgeBrackets {
  AGE_0_17 = '0-17',
  AGE_18_24 = '18-24',
  AGE_25_34 = '25-34',
  AGE_35_44 = '35-44',
  AGE_45_54 = '45-54',
  AGE_55_64 = '55-64',
  AGE_65_74 = '65-74',
  AGE_75_UP = '75+',
}

/**
 * This enum needs to be kept exactly in sync with the
 * AGE_BRACKET_50_PLUS question choice set choices.
 */
export enum Age50PlusValues {
  AGE_50_54 = '50-54 years',
  AGE_55_59 = '55-59 years',
  AGE_60_64 = '60-64 years',
  AGE_65_69 = '65-69 years',
  AGE_70_74 = '70-74 years',
  AGE_75_79 = '75-79 years',
  AGE_80_84 = '80-84 years',
  AGE_85_89 = '85-89 years',
  AGE_90_94 = '90-94 years',
  AGE_95_UP = '95 years or older',
}

export enum Age50PlusBrackets {
  AGE_65_DOWN = 'Under 65',
  AGE_65_74 = '65-74',
  AGE_75_84 = '75-84',
  AGE_85_UP = '85+',
}

export const AGE_50_PLUS_VALUES_PER_BRACKET: Record<
  Age50PlusBrackets,
  readonly Age50PlusValues[]
> = {
  [Age50PlusBrackets.AGE_65_DOWN]: [
    Age50PlusValues.AGE_50_54,
    Age50PlusValues.AGE_55_59,
    Age50PlusValues.AGE_60_64,
  ],
  [Age50PlusBrackets.AGE_65_74]: [
    Age50PlusValues.AGE_65_69,
    Age50PlusValues.AGE_70_74,
  ],
  [Age50PlusBrackets.AGE_75_84]: [
    Age50PlusValues.AGE_75_79,
    Age50PlusValues.AGE_80_84,
  ],
  [Age50PlusBrackets.AGE_85_UP]: [
    Age50PlusValues.AGE_85_89,
    Age50PlusValues.AGE_90_94,
    Age50PlusValues.AGE_95_UP,
  ],
};

/**
 * This is used in the UI to build links for super-admins,
 * and is also used in the BE for reporting based on the response methodology ("sample").
 * If you update this, make sure to also update respondent-attribute-values for pn-methods
 * question-set-attributes
 */
export enum Methodology {
  OPEN = 'open',
  MAILED_SURVEY = 'paper',
  MAILED_INVITE = 'link',
  GROUP_A = 'group-a',
  GROUP_B = 'group-b',
  GROUP_C = 'group-c',
  GROUP_C_E = 'group-c-e',
}

export enum QuestionSetAttributeTypes {
  EMAIL_INVITE_ATTRIBUTE = 'pn-email-invite',
  LANGUAGE = 'pn-language',
  AREA = 'pn-area',
  METHOD = 'pn-method',
  REPOST_VOTE_PUBLISHER_CONTEXT = 'pn-repost-pub',
}

export const QUESTION_SET_ATTRIBUTE_TYPES_DIMENSION_GROUP_NAMES: Partial<
  Record<QuestionSetAttributeTypes, string>
> = {
  [QuestionSetAttributeTypes.AREA]: 'Location',
  [QuestionSetAttributeTypes.METHOD]: 'Methodology',
};

export type QuestionSetAttributeType =
  | Case<QuestionSetAttributeTypes.AREA>
  | Case<QuestionSetAttributeTypes.EMAIL_INVITE_ATTRIBUTE>
  | Case<QuestionSetAttributeTypes.LANGUAGE>
  | Case<QuestionSetAttributeTypes.METHOD>
  | Case<QuestionSetAttributeTypes.REPOST_VOTE_PUBLISHER_CONTEXT>
  | Case<'Location'>
  | Case<'Methodology'>;

export enum REPORT_CONTENT_TEMPLATE_KEY {
  ARPA_TOPICS_IMG = 'ARPA_TOPICS_IMG',
  CASOA_GOALS_IMG = 'CASOA_GOALS_IMG',
  CASOA_DOMAIN_DESCRIPTION_IMG = 'CASOA_DOMAIN_DESCRIPTION_IMG',
}

export enum ReportGenerationStatus {
  PENDING = 'PENDING',
  TAGGED = 'TAGGED',
  NOT_TAGGED = 'NOT_TAGGED',
}

export enum GET_STATUS_PDF_FAILURE_REASON {
  CONTENT_NOT_FOUND = 'CONTENT_NOT_FOUND',
  CONTENT_PDF_SECTIONS_NOT_FOUND = 'CONTENT_PDF_SECTIONS_NOT_FOUND',
  PUBLISHING_ENTITY_NOT_FOUND = 'PUBLISHING_ENTITY_NOT_FOUND',
  ERROR_GETTING_PDF_METADATA = 'ERROR_GETTING_PDF_METADATA',
}

export interface PdfData {
  readonly lastModified: Date | null;
  readonly uploadUrl: string | null;
  readonly status: ReportGenerationStatus | null;
}
