import React, { ErrorInfo } from 'react';
import { errorLogger } from 'client/shared/core/error-handler';

interface Props {
  readonly ErrorComponent?: React.FC<{ readonly error?: Error }>;
}

interface State {
  readonly error: Error | null;
}

export class ErrorBoundary extends React.Component<Props, State> {
  public readonly state: State = {
    error: null,
  };

  public static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    errorLogger.log(error.message, error, errorInfo.componentStack);
  }

  render() {
    const { ErrorComponent } = this.props;
    if (this.state.error) {
      // You can render any custom fallback UI
      if (ErrorComponent) {
        return <ErrorComponent error={this.state.error} />;
      }
      return (
        <h2 className="text-danger">
          {this.state.error.message || 'Something went wrong.'}
        </h2>
      );
    }

    return this.props.children;
  }
}
