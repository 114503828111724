import * as React from 'react';
import './styles.scss';
// This is the one spot where it is ok to do the Link based import. Everyone else should use this component instead
// eslint-disable-next-line no-restricted-imports
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { MaterialIcon, MaterialIconName } from '../material-icon';

export enum LinkType {
  SAME_APP = 'SAME_APP',
  DIFFERENT_APP = 'DIFFERENT_APP',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
}

export interface AppLinkProps {
  readonly ariaLabel?: string;
  readonly to: string;
  readonly className?: LinkProps['className'];
  readonly target?: LinkProps['target'];
  readonly unstyled?: boolean;
  readonly useIcon?: boolean;
  readonly tabIndex?: 0 | -1;
  readonly iconFontSize?: string;
  readonly centerIcon?: boolean;
  readonly retainQueryParameters?: readonly string[];
}

const baseClass = 'pn-app-link';

export const AppLink: React.FC<AppLinkProps> = (p) => {
  const location = useLocation();
  const linkType = destinationToLinkType(location.pathname, p.to);
  const classNames = p.unstyled ? 'mod-unstyled' : 'font-weight-bold';
  const className = `${baseClass} ${classNames} ${p.className ?? ''}`;
  const target = p.target;
  const tabIndex = p.tabIndex ?? 0;
  const openInNewIconIfApplies =
    target === '_blank' && p.useIcon ? (
      <MaterialIcon
        className={`${p.iconFontSize ?? 'font-size-sm'} ml-1 ${
          p.centerIcon ? 'align-self-center' : ''
        }`}
        icon={MaterialIconName.OPEN_IN_NEW}
      />
    ) : null;
  const queryParams = new URLSearchParams(location.search);
  const parsedParams =
    p.retainQueryParameters?.reduce(
      (prev, curr) => ({ ...prev, [curr]: queryParams.get(curr) ?? undefined }),
      {} as Record<string, string | undefined>
    ) ?? {};

  const fullUrl = ClientUrlUtils.common.addQueryParams(p.to, parsedParams);
  switch (linkType) {
    case LinkType.SAME_APP:
      return (
        <Link className={className} tabIndex={tabIndex} target={target} to={fullUrl}>
          {p.children}
        </Link>
      );
    case LinkType.DIFFERENT_APP:
    case LinkType.EXTERNAL_LINK:
      const url =
        linkType === LinkType.EXTERNAL_LINK
          ? p.to
          : ClientUrlUtils.common.pathToUrl(p.to);
      return (
        <a
          aria-label={p.ariaLabel}
          className={className}
          href={url}
          tabIndex={tabIndex}
          target={target}
        >
          {p.children}
          {openInNewIconIfApplies}
        </a>
      );
  }
};
AppLink.displayName = 'AppLink';

export function destinationToLinkType(
  currentPathname: string,
  destination: string
): LinkType {
  if (destination.startsWith('http') || destination.startsWith('mailto:')) {
    // If the caller sent us an http / https url, we assume it is external to the app
    // (app links should be relative, not absolute)
    return LinkType.EXTERNAL_LINK;
  }

  if (
    currentPathname.startsWith(
      `${destination}`.substring(0, 4 /** 5 characters to get /xyz */)
    )
  ) {
    // The destination url starts with the same path than the current path.
    // i.e. destination: /admin/store, currentPathname: /admin/content
    return LinkType.SAME_APP;
  }

  // The destination url starts with a different path than the current path.
  // i.e. destination: /res/profile, currentPathname: /admin/content
  return LinkType.DIFFERENT_APP;
}
