export const MIN_DATA_POINTS_FOR_ANALYTICS = 10;

export const DEFAULT_SURVEY_QUESTION_OPTIONALITY = true;

export const TOPIC_SINGLETON_CHOICE_TEXT = 'TOPIC_SINGLETON';
export const TOPIC_SINGLETON_SET_NAME = 'TOPIC_SINGLETON';
export const TOPIC_SINGLETON_CHOICE_ORDER = 0;

export const POLICY_SINGLETON_SET_NAME = 'POLICY_SINGLETON';

export const ACKNOWLEDGE_SINGLETON_CHOICE_TEXT = 'ACKNOWLEDGED';
export const ACKNOWLEDGE_SINGLETON_SET_NAME = 'ACKNOWLEDGE_SINGLETON';
export const ACKNOWLEDGE_SINGLETON_CHOICE_ORDER = 0;

export const MIN_PASSWORD_LENGTH = 5;
export const MIN_SET_NAME_LENGTH = 10;

export const EMAIL_VALIDATION_REGEX = new RegExp(
  '^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]+$',
  'i'
);

/**
 * A Spatial Reference ID is a predefined well known value
 * that tells GIS systems how to map lat/lng data onto the
 * earth (geometry vs geography). More data available via google,
 * but the short version is that 4326 is the defacto US SRID.
 * https://epsg.io/4326
 */
export const SPATIAL_REFERENCE_ID = 4326;

export const EXPOSED_PTM_SCALES: readonly string[] = [];

/** Const that determines how many pubs are returned by GQL when lists of pubs are returned (managedPublishingEntities, registrationRequests) */
export const PUBS_PER_PAGE = 50;

export const SUPPORT_EMAIL = 'help@polco.us';
export const BUG_REPORT_EMAIL = 'bugreport@polco.us';

export const WELCOME_BACK_EMAIL_PARAM_KEY = 'welcome_back';

export const FROM_ADMIN_REGISTRATION_QUERY_PARAM = 'from_registration';

export const TWITTER_AUTH_PUBLISHING_ENTITY_QUERY_PARAM = 'publishing_entity_id';

export const UNKNOWN_BREAKDOWN_VALUE = 'Unknown';

export const IMPORTED_TAG = '[IMPORTED]' as const;

export const CLOUDINARY_PROFILE_COVER_MAX_SIZE = 5242880;

export const DEMO_PUBLISHING_ENTITY_ID = '3b093778-0211-4e0a-b228-f8ae9dde672d';

export const NONCE_PLACEHOLDER = 'NONCE_PLACEHOLDER';
