import { ILoggerError } from './composite-logger';
import Rollbar from 'rollbar';

export class RollbarErrorLogger implements ILoggerError {
  constructor(private readonly rollbar: Rollbar) {}

  log(message: string, error?: Error, metadata?: any) {
    if (this.rollbar) {
      this.rollbar.error(message, error ?? {}, metadata);
    }
  }
}
