export enum EmailVerificationStatus {
  NEEDS_VERIFICATION = 'NEEDS_VERIFICATION',
  VERIFIED = 'VERIFIED',
  VERIFICATION_NOT_NEEDED = 'VERIFICATION_NOT_NEEDED',
}

export enum IntegrationType {
  ENVISIO = 'envisio',
}

// Note: because the enum values are using in a url, they are abbreviated versions of the more explicit keys.
export enum ClarificationType {
  EXISTING_ADMIN_REQUESTING_ADMIN = 'existing-admin',
  RESPONDENT_REQUESTING_ADMIN = 'res-requesting-admin',
  ADMIN_REQUESTING_RESPONDENT = 'admin-requesting-res',
}

export enum Conference {
  CCC = 'CCC',
  UNKNOWN = 'UNKNOWN',
}

export enum KnownLibraryToolkitTags {
  COVID = 'covid-19 toolkit',
  LIBRARY = 'library toolkit',
  SCHOOL_DISTRICT = 'k-12 survey toolkit',
}
