import * as React from 'react';

export interface ErrorContextInterface {
  readonly errors: readonly (readonly [Date, string])[];
  readonly addError: (error: string, time?: Date) => void;
}

export const ErrorContext = React.createContext<ErrorContextInterface>({
  errors: [],
  addError: () => {
    throw new Error('ErrorContext.addError called without provider');
  },
});
ErrorContext.displayName = 'ErrorContext';

type ErrorsState = readonly (readonly [Date, string])[];

export const AppErrorContextProvider: React.FC<{
  readonly externalErrorSource?: (
    addErr: (error: string, time?: Date) => void
  ) => void;
}> = (p) => {
  React.useEffect(() => {
    p.externalErrorSource?.(addError);
  });
  const [errors, setErrors] = React.useState<ErrorsState>([]);
  function addError(err: string, time?: Date) {
    setErrors([...errors, [time ?? new Date(), err]]);
  }

  return (
    <ErrorContext.Provider value={{ errors, addError }}>
      {p.children}
    </ErrorContext.Provider>
  );
};
