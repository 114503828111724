import _ from 'lodash';
import * as Apollo from '@apollo/client';
import {
  sleep,
  buildGraphqlCache,
  Result,
  PolcoGqlError,
  ApolloErrorMessage,
  AllPolcoGqlErrorTypes,
  isExpectedError,
  PolcoGqlErrors,
} from 'core';
import { GraphQLError } from 'graphql';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { hasCurrentUser } from '../graphql-mutations/helpers';
import { ErrorLink } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { getConfig } from '../core/config';
import { errorLogger } from '../core/error-handler';
import { QueryInfo, QueryStoreValue } from '@apollo/client/core/QueryInfo';
import { ApolloClient, ApolloLink, HttpLink, split } from '@apollo/client';
import { QueryManager } from '@apollo/client/core/QueryManager';
import { WebSocketLink } from './subscriptions';
import { QueryInfoContext } from '../containers/query';

function hasUnauthenticatedError(errs: ReadonlyArray<GraphQLError>) {
  return errs.find((err) => {
    if (isApolloServerError(err)) {
      return err.code === ApolloErrorMessage.UNAUTHENTICATED;
    }
    return false;
  });
}

class ErrorLinkWrapper {
  public readonly link: ApolloLink;
  private apiUnauthenticatedHandler: (() => void) | null;
  private errorHandler: ((error: string) => void) | null;
  constructor() {
    this.apiUnauthenticatedHandler = null;
    this.errorHandler = null;
    this.link = new ErrorLink(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((err) => {
          const ex = err.extensions?.exception?.stacktrace?.join('\n') as
            | string
            | undefined;
          const msg = `[GraphQL error]: Message: ${ex ?? err.message}`;
          console.error(msg, err);
          if (err.extensions?.code === 'INTERNAL_SERVER_ERROR') {
            errorLogger.log(msg, err);
          }

          // The "extra" property signifies that this is a "known" error and so we should not
          // call the error handler, as the caller should know how to handle it
          if (!isExpectedError(err)) {
            this.errorHandler?.(ex ?? err.message);
          }
        });

        if (hasUnauthenticatedError(graphQLErrors)) {
          this.apiUnauthenticatedHandler?.();
        }
      }
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
        errorLogger.log('Network error', networkError);
      }
    }) as ApolloLink;
  }

  public onApiUnauthenticated(fn: () => void) {
    this.apiUnauthenticatedHandler = fn;
  }
  public onError(fn: (error: string) => void) {
    this.errorHandler = fn;
  }
}

function createBatchHttpLink(): ApolloLink {
  return new BatchHttpLink({
    uri: '/n/graphql',
    batchInterval: 10,
    credentials: 'same-origin',
  });
}

function createNonBatchHttpLink(): ApolloLink {
  return new HttpLink({
    uri: '/n/graphql',
    credentials: 'same-origin',
  });
}

function createHttpLink(): ApolloLink {
  return split(
    (operation) => {
      const context = operation.getContext() as QueryInfoContext;
      return !!context.neverBatch;
    },
    createNonBatchHttpLink(),
    createBatchHttpLink()
  );
}

export type MutationStoreValue = NonNullable<QueryManager<any>['mutationStore']>[''];

export interface ApolloOpInfo {
  readonly id: string;
  readonly timeOffset: number;
}

export type QueryStoreValuePlus = QueryStoreValue & {
  readonly skip: boolean;
  readonly queryName: string;
  readonly diffComplete: boolean | undefined;
};

export type MutationStoreValuePlus = MutationStoreValue & {
  readonly mutationName: string;
};

export type QueryOrMutationOp = ApolloOpInfo &
  (QueryStoreValuePlus | MutationStoreValuePlus);

enum ApolloWaitResultFailureReason {
  SPECIFIED_QUERY_NOT_SEEN = 'SPECIFIED_QUERY_NOT_SEEN',
}

export type ApolloWaitResult = Result.Type<
  readonly QueryOrMutationOp[], // seen ops
  QueryOrMutationOp | ApolloWaitResultFailureReason // didn't finish
>;

function getQueryStore(
  client: ApolloClient<any>
): Record<string, QueryStoreValuePlus> {
  const queryManager = (client as any).queryManager as QueryManager<any>;
  const queryMap = (queryManager as any).queries as ReadonlyMap<string, QueryInfo>;
  const ret: Record<string, QueryStoreValuePlus> = {};
  queryMap.forEach((queryInfo, id) => {
    const def = queryInfo.document?.definitions[0];
    const queryName =
      def && def.kind === 'OperationDefinition' ? def.name?.value : undefined;
    const diffComplete = queryInfo.getDiff().complete;
    const skip = ((queryInfo.observableQuery?.options ?? {}) as any).skip;
    ret[id] = {
      ..._.pick(
        queryInfo,
        'variables',
        'networkStatus',
        'networkError',
        'graphqlErrors'
      ),
      skip,
      queryName: queryName ?? 'UNKNOWN',
      diffComplete,
    };
  });
  return ret;
}

function getMutationStore(client: ApolloClient<any>): {
  readonly [mutationId: string]: MutationStoreValuePlus;
} {
  const queryManager = (client as any).queryManager as QueryManager<any>;
  const { mutationStore } = queryManager;
  if (!mutationStore) {
    return {};
  }

  const mutationStorePlus = Object.entries(mutationStore).reduce<
    Record<string, MutationStoreValuePlus>
  >((store, [id, storeValue]) => {
    const def = storeValue.mutation.definitions[0];
    const mutationName =
      def && def.kind === 'OperationDefinition' ? def.name?.value : undefined;
    return {
      ...store,
      [id]: { ...storeValue, mutationName: mutationName ?? 'UNKOWN' },
    };
  }, {});

  return mutationStorePlus;
}

function isRunning(q: QueryStoreValuePlus): boolean {
  const status = q.networkStatus ?? 0;
  if (status >= Apollo.NetworkStatus.ready) {
    return false;
  }
  if (q.skip) {
    return false;
  }
  return true;
}

export async function waitForApolloClient(
  client: ApolloClient<NormalizedCacheObject>,
  args?: {
    readonly waitForOperations?: readonly (
      | Partial<QueryStoreValuePlus>
      | Partial<MutationStoreValuePlus>
    )[];
  }
): Promise<ApolloWaitResult> {
  const maxWait = process.env.DEBUGGING ? 999999999999 : 1000 * 35;
  const start = new Date().getTime();
  const end = start + maxWait;
  const interval = 50;
  let runningOps: readonly QueryOrMutationOp[] = [];
  const seenOps: QueryOrMutationOp[] = [];
  const waitForOperations = args?.waitForOperations ?? [];

  let now = 0;
  while ((now = new Date().getTime()) < end) {
    await sleep(interval);
    const qStore = getQueryStore(client);
    const timeOffset = now - start;
    // no status means nothing run yet
    // networkError === undefined implies skip
    const mappedQueries = _.values(
      _.mapValues(qStore, (v, id): ApolloOpInfo & QueryStoreValuePlus => ({
        ...v,
        id,
        timeOffset,
      }))
    );
    runningOps = mappedQueries.filter((v) => isRunning(v));
    if (runningOps.length > 0) {
      seenOps.push(...runningOps);
      continue;
    }
    const mStore = getMutationStore(client);
    const mappedMutations = _.values(
      _.mapValues(mStore, (v, id): ApolloOpInfo & MutationStoreValuePlus => ({
        ...v,
        id,
        timeOffset,
      }))
    );
    runningOps = mappedMutations.filter((v) => v.loading);
    if (runningOps.length > 0) {
      seenOps.push(...runningOps);
      continue;
    }
    if (isWaitingOnOperation()) {
      continue;
    }
    return Result.success(_.uniqBy(seenOps, (o) => o.id));
  }

  if (runningOps.length > 0) {
    return Result.failure(runningOps[0]);
  } else if (isWaitingOnOperation()) {
    return Result.failure(ApolloWaitResultFailureReason.SPECIFIED_QUERY_NOT_SEEN);
  } else {
    return Result.success(_.uniqBy(seenOps, (o) => o.id));
  }

  function isWaitingOnOperation() {
    return waitForOperations.some(
      (waitForOp) => !seenOps.some((seenOp) => _.isMatch(seenOp, waitForOp))
    );
  }
}

async function resetStoreIfLoggedIn(
  cli: ApolloClient<NormalizedCacheObject>,
  _cache: InMemoryCache
) {
  if (hasCurrentUser(cli)) {
    // TODO try this later to see if it removes the 'reset store while in flight'
    // await waitForApolloClient(cli);
    await cli.resetStore();
    // await cache.reset();
    // await (cli.queryManager && cli.queryManager.reFetchObservableQueries());
  }
}

export function buildGraphqlClient(clientConfig: {
  readonly name: string;
  readonly version?: string;
}) {
  const cache = buildGraphqlCache();

  const errLink = new ErrorLinkWrapper();
  const httpLink = createHttpLink();

  const websocketUri = getConfig().websocketUri;
  const wsLink = new WebSocketLink({
    url: `${websocketUri}/n/subscriptions`,
  });

  const splitLink = ApolloLink.from([
    errLink.link,
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    ),
  ]);

  const cli = new ApolloClient<NormalizedCacheObject>({
    connectToDevTools:
      process.env.NODE_ENV !== 'production' ||
      process.env.ENABLE_APOLLO_DEV_TOOLS === 'true',
    assumeImmutableResults: true,
    cache,
    link: splitLink,
    version: '1.0',
    ...clientConfig,
  });
  errLink.onApiUnauthenticated(() => resetStoreIfLoggedIn(cli, cache));

  return {
    graphqlClient: cli,
    setErrorHandler: (onErrorCallback: (error: string) => void) =>
      errLink.onError(onErrorCallback),
  };
}

export function isPolcoGqlError<T extends AllPolcoGqlErrorTypes>(
  err: GraphQLError
): err is PolcoGqlError<T> {
  return !!(err as any).extra;
}

export function extractApiError(
  result: Apollo.FetchResult
): PolcoGqlErrors.PolcoApiError | null {
  const errs = _.compact(
    result.errors?.map((err) => {
      const expectedError = isPolcoGqlError<PolcoGqlErrors.PolcoApiError>(err);
      // isPolcoGqlError is essentially casting the error as PolcoApiError. This tries to confirm it.
      const apiErrorKey: keyof PolcoGqlErrors.PolcoApiError = 'statusCode';
      if (expectedError && apiErrorKey in err.extra.errors) {
        return err.extra.errors;
      }
    })
  );
  if (errs.length === 0) {
    return null;
  }

  return errs[0];
}

export interface ApolloServerError extends GraphQLError {
  readonly code: 'UNAUTHENTICATED' | 'FORBIDDEN' | 'BAD_USER_INPUT';
}

export function isApolloServerError(err: GraphQLError): err is ApolloServerError {
  return !!(err as any).code;
}
