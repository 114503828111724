import * as React from 'react';
import { Snackbar, SnackbarOrigin } from '@material-ui/core';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
  Well,
  WellType,
} from '../base';

export interface Props {
  readonly allowClose?: boolean;
  readonly timeMs?: number;
  readonly message: string;
  readonly position: SnackbarPositions;
  readonly type: WellType;
}

export enum SnackbarPositions {
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
}

const mapSnackbarPositions: Record<SnackbarPositions, SnackbarOrigin> = {
  [SnackbarPositions.BOTTOM_LEFT]: { vertical: 'bottom', horizontal: 'left' },
  [SnackbarPositions.BOTTOM_RIGHT]: { vertical: 'bottom', horizontal: 'right' },
  [SnackbarPositions.TOP_LEFT]: { vertical: 'top', horizontal: 'left' },
  [SnackbarPositions.TOP_RIGHT]: { vertical: 'top', horizontal: 'right' },
};

const baseClass = 'pn-snackbar-notification';

export const SnackbarNotification: React.FC<Props> = (p) => {
  const [open, setOpen] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (p.timeMs) {
      setTimeout(() => setOpen(false), p.timeMs);
    }
  }, [p.message, p.allowClose, p.position, p.timeMs]);

  if (!p.message) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={mapSnackbarPositions[p.position]}
      className={baseClass}
      open={open}
    >
      <Well ariaLive="polite" type={p.type}>
        <div className="d-flex align-items-center">
          <span>{p.message}</span>
          {p.allowClose && (
            <Btn
              action={() => setOpen(false)}
              className="ml-1"
              iconOnly
              size="small"
              type={ButtonTypes.SEAMLESS}
            >
              <MaterialIcon icon={MaterialIconName.CLOSE} />
            </Btn>
          )}
        </div>
      </Well>
    </Snackbar>
  );
};

SnackbarNotification.defaultProps = {
  position: SnackbarPositions.BOTTOM_RIGHT,
};
