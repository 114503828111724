import * as React from 'react';

export interface BrowserHookInfo {
  readonly thirdPartyCookiesEnabled: () => boolean;
  readonly openNewWindow: (url: string) => void;
  readonly externalNavigation: (url: string) => void;
}

export const BrowserContext = React.createContext<BrowserHookInfo>(
  defaultBrowserHookInfo()
);

export function defaultBrowserHookInfo(): BrowserHookInfo {
  // not going to change per app load
  const thirdPartyCookiesEnabled = thirdPartyCookiesAreEnabled();
  return {
    thirdPartyCookiesEnabled: () => thirdPartyCookiesEnabled,
    openNewWindow: (url) => window.open(url, '_blank'),
    externalNavigation: (url) => {
      window.location.href = url;
    },
  };
}

function thirdPartyCookiesAreEnabled() {
  const CookieName = 'PolcoTestCookie';
  document.cookie = `CookieName=${CookieName};samesite=None; secure`;

  return document.cookie.indexOf(CookieName) >= 0;
}

export function useBrowser(): BrowserHookInfo {
  const context = React.useContext(BrowserContext);
  return context;
}
