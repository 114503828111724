import * as React from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import './styles.scss';

const baseClass = 'pn-checkbox';

export interface Props {
  readonly ariaInvalid?: boolean;
  readonly applyAccessibleStyles?: boolean;
  readonly className?: string;
  readonly label: string;
  readonly disabled?: boolean;
  readonly checked: boolean;
  readonly onChecked: (checked: boolean) => void;
  readonly htmlId: string;
  readonly required?: boolean;
}

export const Checkbox: React.FC<Props> = (p: Props) => {
  if (process.env.NODE_ENV === 'test') {
    return (
      <label className={classNames(baseClass, p.className)}>
        <input
          checked={p.checked}
          disabled={p.disabled}
          id={p.htmlId}
          onChange={(x) => p.onChecked(x.target.checked)}
          required={p.required}
          type="checkbox"
        />
        {p.label}
      </label>
    );
  }

  return (
    <Form.Check
      aria-invalid={p.ariaInvalid}
      checked={p.checked}
      className={classNames(baseClass, p.className, {
        [`${baseClass}-accessible-check`]: p.applyAccessibleStyles,
        'is-checked': p.checked,
      })}
      custom
      disabled={p.disabled}
      id={p.htmlId}
      label={p.label}
      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
        p.onChecked(evt.target.checked);
      }}
      required={p.required}
      type="checkbox"
    />
  );
};
