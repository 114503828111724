import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './styles.scss';
import classNames from 'classnames';
import { KeyPressKey } from 'client/shared/core/types';

const useMountedState = () => {
  const ref = useRef<boolean>(false);
  const get = useCallback(() => ref.current, []);

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, [ref]);

  return get;
};

export interface Props {
  readonly className?: string;
  readonly disabled?: boolean;
  readonly danger?: boolean;
  readonly unstyled?: boolean;
  readonly size?: 'small' | 'xsmall' | 'default';
  action(e: LinkEvent): Promise<unknown> | void;
  readonly onFocus?: React.FocusEventHandler<HTMLElement>;
  readonly onBlur?: React.FocusEventHandler<HTMLElement>;
  readonly onEscape?: React.KeyboardEventHandler;
  readonly underlineOnHover?: boolean;
  readonly ariaLabel?: string;
}

export type LinkEvent =
  | React.MouseEvent<HTMLElement>
  | React.KeyboardEvent<HTMLElement>;

export const ActionLink: React.FC<Props> = ({
  action,
  children,
  className,
  danger,
  disabled,
  size,
  unstyled,
  onFocus,
  onBlur,
  onEscape,
  ariaLabel,
  underlineOnHover,
}) => {
  const isMounted = useMountedState();
  const [promisedActionRunning, setPromisedActionRunning] = useState(false);
  const statusClass = disabled ? 'is-disabled' : 'is-enabled';

  const onKeyDown = async (e: React.KeyboardEvent<any>) => {
    if (e.key === KeyPressKey.ENTER) {
      await handleClick(e);
    }
    if (e.key === KeyPressKey.ESCAPE) {
      onEscape?.(e);
    }
  };

  const handleClick = useCallback(
    async (evt: LinkEvent) => {
      evt.preventDefault();

      if (disabled || promisedActionRunning) {
        return;
      }

      const promisedAction = action(evt);
      if (promisedAction) {
        setPromisedActionRunning(true);
        try {
          await promisedAction;
        } finally {
          if (isMounted()) {
            setPromisedActionRunning(false);
          }
        }
      }
    },
    [disabled, promisedActionRunning, action, isMounted]
  );

  const fontSize = useMemo(() => {
    switch (size) {
      case 'small':
        return 'font-size-sm';
      case 'xsmall':
        return 'font-size-xs';
      default:
        return '';
    }
  }, [size]);
  const classes = classNames(
    'pn-action-link',
    'cursor-pointer',
    danger && 'text-danger is-danger-link',
    fontSize,
    className,
    statusClass,
    underlineOnHover && 'hover-underline',
    ...(unstyled
      ? []
      : [
          'font-weight-bold',
          'p-0',
          'm-0',
          'align-baseline',
          'bg-transparent',
          'border-0',
        ])
  );

  return (
    <span
      aria-label={ariaLabel}
      className={classes}
      onBlur={onBlur}
      onClick={handleClick}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      role="link"
      tabIndex={0}
    >
      {children}
    </span>
  );
};

ActionLink.displayName = 'ActionLink';
