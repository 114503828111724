import * as React from 'react';
import './styles.scss';

export enum MaterialIconName {
  ADD = 'add',
  ACCESS_TIME = 'access_time',
  ACCESSIBLE = 'accessible',
  ACCOUNT_CIRCLE = 'account_circle',
  ADD_CIRCLE_OUTLINE = 'add_circle_outline',
  ADD_COMMENT = 'add_comment',
  ADDCHART = 'addchart',
  ADD_PHOTO_ALTERNATE = 'add_photo_alternate',
  ADMIN_PANEL_SETTINGS = 'admin_panel_settings',
  ALARM = 'alarm',
  ARROW_BACK = 'arrow_back',
  ARROW_BACK_IOS = 'arrow_back_ios',
  ARROW_DROP_DOWN = 'arrow_drop_down',
  ARROW_DROP_UP = 'arrow_drop_up',
  ARROW_FORWARD = 'arrow_forward',
  ARROW_FORWARD_IOS = 'arrow_forward_ios',
  ARTICLE = 'article',
  ASSIGNMENT = 'assignment',
  ATTACH_MONEY = 'attach_money',
  AUTO_STORIES = 'auto_stories',
  BALANCE = 'balance',
  BAR_CHART = 'bar_chart',
  BALLOT = 'ballot',
  BOOKMARK = 'bookmark',
  BOOKMARK_BORDER = 'bookmark_border',
  CALENDAR_TODAY = 'calendar_today',
  CANCEL = 'cancel',
  CANCEL_PRESENTATION = 'cancel_presentation',
  CENTER_FOCUS_STRONG = 'center_focus_strong',
  CHAT = 'chat',
  CHECK = 'check',
  CHECK_CIRCLE = 'check_circle',
  CHECK_CIRCLE_ICON = 'check_circle_icon',
  CHECK_CIRCLE_OUTLINE = 'check_circle_outline',
  CHEVRON_RIGHT = 'chevron_right',
  CIRCLE = 'circle',
  CLEAR = 'clear',
  CLOSE = 'close',
  CLOSE_ICON = 'close_icon',
  CONTENT_COPY = 'content_copy',
  DATA_USAGE = 'data_usage',
  DELETE = 'delete',
  DONE = 'done',
  DONE_OUTLINE = 'done_outline',
  DOWNLOAD = 'download',
  DRAG_INDICATOR = 'drag_indicator',
  DVR = 'dvr',
  EDIT = 'edit',
  EMAIL = 'email',
  ERROR = 'error',
  ERROR_OUTLINE = 'error_outline',
  EXIT_TO_APP = 'exit_to_app',
  EXPAND_LESS = 'expand_less',
  EXPAND_MORE = 'expand_more',
  FAVORITE = 'favorite',
  FAVORITE_BORDER = 'favorite_border',
  FILE_COPY = 'file_copy',
  FLAG = 'flag',
  FONT_DOWNLOAD = 'font_download',
  FORMAT_LIST_NUMBERED = 'format_list_numbered',
  FORUM = 'forum',
  GROUPS_OUTLINE = 'groups_outline',
  HELP = 'help',
  HELP_OUTLINE = 'help_outline',
  HIGHLIGHT_OFF = 'highlight_off',
  HISTORY = 'history',
  HOME = 'home',
  HOUSE = 'house',
  HOW_TO_VOTE = 'how_to_vote',
  INFO = 'info',
  INFO_OUTLINE = 'info_outline',
  IOS_SHARE = 'ios_share',
  INSERT_CHART_OUTLINED = 'insert_chart_outlined',
  KEYBOARD_ARROW_DOWN = 'keyboard_arrow_down',
  KEYBOARD_ARROW_LEFT = 'keyboard_arrow_left',
  KEYBOARD_ARROW_RIGHT = 'keyboard_arrow_right',
  KEYBOARD_ARROW_UP = 'keyboard_arrow_up',
  KEYBOARD_BACKSPACE = 'keyboard_backspace',
  KEYBOARD_TAB = 'keyboard_tab',
  LAST_PAGE = 'last_page',
  LEADERBOARD = 'leaderboard',
  LEGEND_TOGGLE = 'legend_toggle',
  LIGHTBULB = 'lightbulb',
  LIGHTBULB_OUTLINE = 'lightbulb_outline',
  LIST_ALT = 'list_alt',
  LIVE_TV = 'live_tv',
  LOCAL_ATM = 'local_atm',
  LOCAL_LIBRARY = 'local_library',
  LOCK = 'lock',
  MAP = 'map',
  MENU = 'menu',
  MENU_OPEN = 'menu_open',
  MORE_HORIZ = 'more_horiz',
  MORE_VERT = 'more_vert',
  NAVIGATION = 'navigation',
  NORTH_EAST = 'north_east',
  NOT_LISTED_LOCATION = 'not_listed_location',
  NOTIFICATIONS_ACTIVE = 'notifications_active',
  NOTIFICATIONS_NONE = 'notifications_none',
  OPEN_IN_NEW = 'open_in_new',
  PAID = 'paid',
  PAUSE = 'pause',
  PEOPLE = 'people',
  PERSON_ADD_ALT_1 = 'person_add_alt_1',
  PERSON_REMOVE_ALT_1 = 'person_remove_alt_1',
  POLL = 'poll',
  PRINT = 'print',
  PUBLIC = 'public',
  QUESTION_MARK = 'question_mark',
  RECEIPT = 'receipt',
  REFRESH = 'refresh',
  REMOVE = 'remove',
  REMOVE_CIRCLE = 'remove_circle',
  REPEAT = 'repeat',
  REPLY = 'reply',
  RESTORE = 'restore',
  RSS_FEED = 'rss_feed',
  RULE = 'rule',
  SCHOOL = 'school',
  SEARCH = 'search',
  SEND = 'send',
  SENSOR_DOOR = 'sensor_door',
  SHARE = 'share',
  SHOW_CHART = 'show_chart',
  SORT = 'sort',
  SOUTH_EAST = 'south_east',
  STACKED_LINE_CHART = 'stacked_line_chart',
  STAR = 'star',
  STARS = 'stars',
  STOP = 'stop',
  THUMB_UP = 'thumb_up',
  THUMBS_UP_DOWN = 'thumbs_up_down',
  TV_OFF = 'tv_off',
  UNFOLD_LESS = 'unfold_less',
  UNFOLD_MORE = 'unfold_more',
  VERIFIED = 'verified',
  VERIFIED_USER = 'verified_user',
  VISIBILITY = 'visibility',
  VISIBILITY_OFF = 'visibility_off',
  WARNING = 'warning',
  WAVING_HAND = 'waving_hand',
}

export type MaterialIconType =
  | 'material-icons'
  | 'material-icons-round'
  | 'material-icons-outlined';
export interface MaterialIconProps {
  readonly className?: string;
  readonly icon: MaterialIconName;
  readonly iconType?: MaterialIconType;
  readonly style?: React.CSSProperties;
  readonly ariaHidden?: boolean;
  readonly ariaLabel?: string;
  readonly role?: React.AriaRole;
}

export const MaterialIcon: React.FC<MaterialIconProps> = (props) => {
  return (
    <span
      aria-hidden={props.ariaHidden ?? false}
      aria-label={props.ariaLabel}
      className={`${props.iconType ?? 'material-icons'} ${props.className || ''}`}
      data-testid="material-icons"
      role={props.role || (props.ariaLabel ? 'img' : '')}
      style={props.style}
    >
      {props.icon}
    </span>
  );
};
