import { PermissionType } from '@polco-us/types';
import { Prefix, QuestionSetType } from 'core';
import {
  QuestionSetActions,
  QuestionSetCommonPermissionType,
  QuestionSetCommonPermissionTypeKeys,
  QuestionSetStatus,
} from './types/permissions';

export { QuestionSetCommonPermissionTypeKeys } from './types/permissions';

export interface QuestionSetPermissions extends Prefix<QuestionSetActions, 'CAN'> {}

export function getQuestionSetPermissions(
  setType: QuestionSetType,
  adminPermissions: ReadonlyArray<PermissionType>
): QuestionSetPermissions {
  const setPermissions = QUESTION_SET_COMMON_PERMISSIONS[setType];
  const [
    canEdit,
    canCopy,
    canDelete,
    canPublish,
    canShareAndManageAccess,
    canDownloadReport,
    canPrintPreview,
    canViewRespondentView,
    canCreateInnerPoll,
    canDeleteInnerPoll,
    canEditInnerPoll,
    canShareInnerPoll,
    canPrepareOutcome,
  ] = [
    setPermissions.EDIT,
    setPermissions.COPY,
    setPermissions.DELETE,
    setPermissions.PUBLISH,
    setPermissions.SHARE_AND_MANAGE_ACCESS,
    setPermissions.DOWNLOAD_REPORT,
    setPermissions.PRINT_PREVIEW,
    setPermissions.VIEW_RESPONDENT_VIEW,
    setPermissions.CREATE_INNER_POLL,
    setPermissions.DELETE_INNER_POLL,
    setPermissions.EDIT_INNER_POLL,
    setPermissions.SHARE_INNER_POLL,
    setPermissions.PREPARE_OUTCOME,
  ].map((p) => p !== undefined && adminPermissions.includes(p));
  return {
    CAN_EDIT: canEdit,
    CAN_COPY: canCopy,
    CAN_DELETE: canDelete,
    CAN_PUBLISH: canPublish,
    CAN_SHARE_AND_MANAGE_ACCESS: canShareAndManageAccess,
    CAN_DOWNLOAD_REPORT: canDownloadReport,
    CAN_PRINT_PREVIEW: canPrintPreview,
    CAN_VIEW_RESPONDENT_VIEW: canViewRespondentView,
    CAN_CREATE_INNER_POLL: canCreateInnerPoll,
    CAN_DELETE_INNER_POLL: canDeleteInnerPoll,
    CAN_EDIT_INNER_POLL: canEditInnerPoll,
    CAN_SHARE_INNER_POLL: canShareInnerPoll,
    CAN_PREPARE_OUTCOME: canPrepareOutcome,
  };
}

export const QUESTION_SET_COMMON_PERMISSIONS: QuestionSetCommonPermissionType = {
  [QuestionSetType.SURVEY]: {
    [QuestionSetCommonPermissionTypeKeys.CREATE]: PermissionType.CREATE_SURVEY,
    [QuestionSetCommonPermissionTypeKeys.EDIT]: PermissionType.EDIT_SURVEY,
    [QuestionSetCommonPermissionTypeKeys.DELETE]: PermissionType.DELETE_SURVEY,
    [QuestionSetCommonPermissionTypeKeys.COPY]: PermissionType.COPY_SURVEY,
    [QuestionSetCommonPermissionTypeKeys.PUBLISH]: PermissionType.PUBLISH_SURVEY,
    [QuestionSetCommonPermissionTypeKeys.VIEW_RESULTS]:
      PermissionType.VIEW_SURVEY_RESULTS,
    [QuestionSetCommonPermissionTypeKeys.VIEW]: {
      [QuestionSetStatus.NOT_STARTED]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_NOT_STARTED_SURVEY,
      ],
      [QuestionSetStatus.IN_PROGRESS]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_IN_PROGRESS_SURVEY,
      ],
      [QuestionSetStatus.CLOSED]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_CLOSED_SURVEY,
      ],
      [QuestionSetStatus.SCHEDULED]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_NOT_STARTED_SURVEY,
      ],
      [QuestionSetStatus.ARCHIVED]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_CLOSED_SURVEY,
      ],
      [QuestionSetStatus.HISTORIC_RECORD]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_CLOSED_SURVEY,
      ],
      [QuestionSetStatus.SOFT_DELETED]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_CLOSED_SURVEY,
      ],
    },
    [QuestionSetCommonPermissionTypeKeys.SHARE_AND_MANAGE_ACCESS]:
      PermissionType.SHARE_AND_MANAGE_ACCESS_BUTTON,
    [QuestionSetCommonPermissionTypeKeys.DOWNLOAD_REPORT]:
      PermissionType.DOWNLOADABLE_REPORT,
    [QuestionSetCommonPermissionTypeKeys.PRINT_PREVIEW]:
      PermissionType.PRINTABLE_PREVIEW,
    [QuestionSetCommonPermissionTypeKeys.VIEW_RESPONDENT_VIEW]:
      PermissionType.VIEW_RESPONDENT_VIEW,
    [QuestionSetCommonPermissionTypeKeys.PREPARE_OUTCOME]:
      PermissionType.PREPARE_OUTCOME,
  },
  [QuestionSetType.CONTENT_POST]: {
    //TODO maybe make new permissions for these -- ENG-3606
    [QuestionSetCommonPermissionTypeKeys.CREATE]: PermissionType.CREATE_SURVEY,
    [QuestionSetCommonPermissionTypeKeys.EDIT]: PermissionType.EDIT_SURVEY,
    [QuestionSetCommonPermissionTypeKeys.DELETE]: PermissionType.DELETE_SURVEY,
    [QuestionSetCommonPermissionTypeKeys.COPY]: PermissionType.COPY_SURVEY,
    [QuestionSetCommonPermissionTypeKeys.PUBLISH]: PermissionType.PUBLISH_SURVEY,
    [QuestionSetCommonPermissionTypeKeys.VIEW_RESULTS]:
      PermissionType.VIEW_SURVEY_RESULTS,
    [QuestionSetCommonPermissionTypeKeys.VIEW]: {
      [QuestionSetStatus.NOT_STARTED]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_NOT_STARTED_SURVEY,
      ],
      [QuestionSetStatus.IN_PROGRESS]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_IN_PROGRESS_SURVEY,
      ],
      [QuestionSetStatus.CLOSED]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_CLOSED_SURVEY,
      ],
      [QuestionSetStatus.SCHEDULED]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_NOT_STARTED_SURVEY,
      ],
      [QuestionSetStatus.ARCHIVED]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_CLOSED_SURVEY,
      ],
      [QuestionSetStatus.HISTORIC_RECORD]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_CLOSED_SURVEY,
      ],
      [QuestionSetStatus.SOFT_DELETED]: [
        PermissionType.VIEW_ALL_SURVEYS_UNDER_PROFILE,
        PermissionType.VIEW_CLOSED_SURVEY,
      ],
    },
    [QuestionSetCommonPermissionTypeKeys.SHARE_AND_MANAGE_ACCESS]:
      PermissionType.SHARE_AND_MANAGE_ACCESS_BUTTON,
    [QuestionSetCommonPermissionTypeKeys.DOWNLOAD_REPORT]:
      PermissionType.DOWNLOADABLE_REPORT,
    [QuestionSetCommonPermissionTypeKeys.PRINT_PREVIEW]:
      PermissionType.PRINTABLE_PREVIEW,
    [QuestionSetCommonPermissionTypeKeys.VIEW_RESPONDENT_VIEW]:
      PermissionType.VIEW_RESPONDENT_VIEW,
    [QuestionSetCommonPermissionTypeKeys.PREPARE_OUTCOME]:
      PermissionType.PREPARE_OUTCOME,
  },
  [QuestionSetType.SET]: {
    [QuestionSetCommonPermissionTypeKeys.CREATE]: PermissionType.CREATE_POLL_SET,
    [QuestionSetCommonPermissionTypeKeys.EDIT]: PermissionType.EDIT_POLL_SET,
    [QuestionSetCommonPermissionTypeKeys.DELETE]: PermissionType.DELETE_POLL_SET,
    [QuestionSetCommonPermissionTypeKeys.COPY]: PermissionType.COPY_POLL_SET,
    [QuestionSetCommonPermissionTypeKeys.PUBLISH]: PermissionType.PUBLISH_POLL,
    // This is a question permission and not a set permission like the others
    [QuestionSetCommonPermissionTypeKeys.VIEW_RESULTS]:
      PermissionType.VIEW_POLL_RESULTS,
    [QuestionSetCommonPermissionTypeKeys.VIEW]: {
      [QuestionSetStatus.NOT_STARTED]: [
        PermissionType.VIEW_ALL_POLL_SETS_UNDER_PROFILE,
        PermissionType.VIEW_NOT_STARTED_POLL_SETS,
      ],
      [QuestionSetStatus.IN_PROGRESS]: [
        PermissionType.VIEW_ALL_POLL_SETS_UNDER_PROFILE,
        PermissionType.VIEW_IN_PROGRESS_POLL_SETS,
      ],
      [QuestionSetStatus.CLOSED]: [
        PermissionType.VIEW_ALL_POLL_SETS_UNDER_PROFILE,
        PermissionType.VIEW_CLOSED_POLL_SET,
      ],
      [QuestionSetStatus.SCHEDULED]: [
        PermissionType.VIEW_ALL_POLL_SETS_UNDER_PROFILE,
        PermissionType.VIEW_NOT_STARTED_POLL_SETS,
      ],
      [QuestionSetStatus.ARCHIVED]: [
        PermissionType.VIEW_ALL_POLL_SETS_UNDER_PROFILE,
        PermissionType.VIEW_CLOSED_POLL_SET,
      ],
      [QuestionSetStatus.HISTORIC_RECORD]: [
        PermissionType.VIEW_ALL_POLL_SETS_UNDER_PROFILE,
        PermissionType.VIEW_CLOSED_POLL_SET,
      ],
      [QuestionSetStatus.SOFT_DELETED]: [
        PermissionType.VIEW_ALL_POLL_SETS_UNDER_PROFILE,
        PermissionType.VIEW_CLOSED_POLL_SET,
      ],
    },
    [QuestionSetCommonPermissionTypeKeys.CREATE_INNER_POLL]:
      PermissionType.CREATE_POLL,
    [QuestionSetCommonPermissionTypeKeys.EDIT_INNER_POLL]: PermissionType.EDIT_POLL,
    [QuestionSetCommonPermissionTypeKeys.DELETE_INNER_POLL]:
      PermissionType.DELETE_POLL_FROM_SET,
    [QuestionSetCommonPermissionTypeKeys.SHARE_INNER_POLL]:
      PermissionType.SHARE_INDIVIDUAL_POLL,
    [QuestionSetCommonPermissionTypeKeys.SHARE_AND_MANAGE_ACCESS]:
      PermissionType.SHARE_AND_MANAGE_ACCESS_BUTTON,
    [QuestionSetCommonPermissionTypeKeys.DOWNLOAD_REPORT]:
      PermissionType.DOWNLOADABLE_REPORT,
    [QuestionSetCommonPermissionTypeKeys.PRINT_PREVIEW]:
      PermissionType.PRINTABLE_PREVIEW,
    [QuestionSetCommonPermissionTypeKeys.VIEW_RESPONDENT_VIEW]:
      PermissionType.VIEW_RESPONDENT_VIEW,
    [QuestionSetCommonPermissionTypeKeys.PREPARE_OUTCOME]:
      PermissionType.PREPARE_OUTCOME,
  },
  [QuestionSetType.POLCO_LIVE]: {
    [QuestionSetCommonPermissionTypeKeys.CREATE]: PermissionType.CREATE_LIVE_EVENT,
    [QuestionSetCommonPermissionTypeKeys.EDIT]: PermissionType.EDIT_LIVE_EVENT,
    [QuestionSetCommonPermissionTypeKeys.DELETE]: PermissionType.DELETE_LIVE_EVENT,
    [QuestionSetCommonPermissionTypeKeys.COPY]: PermissionType.COPY_LIVE_EVENT,
    [QuestionSetCommonPermissionTypeKeys.PUBLISH]: PermissionType.PUBLISH_LIVE_POLL,
    [QuestionSetCommonPermissionTypeKeys.VIEW_RESULTS]:
      PermissionType.VIEW_LIVE_RESULTS_AND_COMMENTS_ON_LIVE_POLLS,
    [QuestionSetCommonPermissionTypeKeys.VIEW]: {
      [QuestionSetStatus.NOT_STARTED]: [
        PermissionType.VIEW_ALL_LIVE_EVENTS_UNDER_SAME_PROFILE,
        PermissionType.VIEW_NOT_STARTED_LIVE_EVENT,
      ],
      [QuestionSetStatus.IN_PROGRESS]: [
        PermissionType.VIEW_ALL_LIVE_EVENTS_UNDER_SAME_PROFILE,
        PermissionType.VIEW_IN_PROGRESS_LIVE_EVENT,
      ],
      [QuestionSetStatus.CLOSED]: [
        PermissionType.VIEW_ALL_LIVE_EVENTS_UNDER_SAME_PROFILE,
        PermissionType.VIEW_CLOSED_LIVE_EVENT,
      ],
      [QuestionSetStatus.SCHEDULED]: [
        PermissionType.VIEW_ALL_LIVE_EVENTS_UNDER_SAME_PROFILE,
        PermissionType.VIEW_NOT_STARTED_LIVE_EVENT,
      ],
      [QuestionSetStatus.ARCHIVED]: [
        PermissionType.VIEW_ALL_LIVE_EVENTS_UNDER_SAME_PROFILE,
        PermissionType.VIEW_CLOSED_LIVE_EVENT,
      ],
      [QuestionSetStatus.HISTORIC_RECORD]: [
        PermissionType.VIEW_ALL_LIVE_EVENTS_UNDER_SAME_PROFILE,
        PermissionType.VIEW_CLOSED_LIVE_EVENT,
      ],
      [QuestionSetStatus.SOFT_DELETED]: [
        PermissionType.VIEW_ALL_LIVE_EVENTS_UNDER_SAME_PROFILE,
        PermissionType.VIEW_CLOSED_LIVE_EVENT,
      ],
    },
    [QuestionSetCommonPermissionTypeKeys.CREATE_INNER_POLL]:
      PermissionType.CREATE_LIVE_POLL,
    [QuestionSetCommonPermissionTypeKeys.EDIT_INNER_POLL]:
      PermissionType.EDIT_LIVE_POLL,
    [QuestionSetCommonPermissionTypeKeys.DELETE_INNER_POLL]:
      PermissionType.DELETE_LIVE_POLL,
    [QuestionSetCommonPermissionTypeKeys.SHARE_AND_MANAGE_ACCESS]:
      PermissionType.SHARE_AND_MANAGE_ACCESS_BUTTON,
    [QuestionSetCommonPermissionTypeKeys.DOWNLOAD_REPORT]:
      PermissionType.DOWNLOADABLE_REPORT,
    [QuestionSetCommonPermissionTypeKeys.PRINT_PREVIEW]:
      PermissionType.PRINTABLE_PREVIEW,
    [QuestionSetCommonPermissionTypeKeys.VIEW_RESPONDENT_VIEW]:
      PermissionType.VIEW_RESPONDENT_VIEW,
    [QuestionSetCommonPermissionTypeKeys.PREPARE_OUTCOME]:
      PermissionType.PREPARE_OUTCOME,
  },
};
