import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { debounce, DebouncedFunc, DebounceSettings } from 'lodash';

export function useWindowSize() {
  const getSize = React.useCallback(() => {
    {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }
  }, []);
  const [windowSize, setWindowSize] = React.useState(getSize);

  React.useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSize]);

  return windowSize;
}

export function usePrintDetector() {
  const [printing, setPrinting] = React.useState(false);

  function updatePrinting(turnOn: boolean) {
    setPrinting(turnOn);
  }

  React.useEffect(() => {
    const mediaQuery: MediaQueryList =
      window.matchMedia && window.matchMedia('print');
    const listener = (queryResult: MediaQueryListEvent) =>
      updatePrinting(!!queryResult.matches);

    mediaQuery.addListener(listener);
    return () => mediaQuery.removeListener(listener);
  }, []);

  return printing;
}

/* Use when only want to call a function once and the dependencies change on every render causing an infinite loop. This should be avoided when at all possible but is here as a last resort */
export function _dangerousUseEffectOnMount(effect: React.EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useEffect(effect, []);
}

// Note - this is a bit undesirable but is being set as a 'global' variable and updated with the useScrollToTop hook so we can keep track of scroll position if you've already visited the page.
const pathNameHistory = new Set<string>();

/* Inspired by = https://reacttraining.com/react-router/web/guides/scroll-restoration
this will reset the scroll to the top when navigating between pages (but maintains scroll position if you've already visited the page, i.e. navigating back) */
export function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect((): void => {
    if (!pathNameHistory.has(pathname)) {
      window.scrollTo(0, 0);
      pathNameHistory.add(pathname);
    }
  }, [pathname]);

  return null;
}

export function useLocalStorage(): Storage | null {
  function isLocalStorageEnabled() {
    const test = '__local_storage_availability_test__';
    try {
      window.localStorage.setItem(test, test);
      window.localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }

  return isLocalStorageEnabled() ? window.localStorage : null;
}

export function usePrevious(value: string | number | undefined) {
  const ref = React.useRef<string | number>();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useSaasGrowthVenturesScript() {
  const url = 'https://cdn.saasgrowthventures.com/storagehub/lib/trigger.min.js';
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  });
}

export function useDebounce<T extends (...args: any) => any>(
  func: T,
  wait?: number,
  options?: DebounceSettings
): DebouncedFunc<T> {
  if (process.env.NODE_ENV === 'test') {
    return debounce(func, 0 /* In test, dont pause */, options);
  }

  return debounce(func, wait, options);
}
