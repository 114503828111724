
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AccessSetting": [
      "AccessSetting_Email",
      "AccessSetting_Everyone",
      "AccessSetting_Link"
    ],
    "Admin": [
      "PrivateAdmin",
      "PublicAdmin"
    ],
    "AdminBase": [
      "PrivateAdmin",
      "PublicAdmin"
    ],
    "AiAssistantSessionBase": [
      "AiAssistantSession",
      "AiAssistantSessionWithStats"
    ],
    "BalancingActReportingCategoryBase": [
      "BalancingActReportingCategory",
      "BalancingActReportingSubcategory"
    ],
    "ContentSet": [
      "ContentPost",
      "PolcoLive",
      "QuestionSet",
      "Survey"
    ],
    "ContentSetBase": [
      "ContentPost",
      "PolcoLive",
      "QuestionSet",
      "Survey"
    ],
    "CrosstabResults": [
      "CrosstabGridResults",
      "CrosstabQuestionResults"
    ],
    "EmailLoginResult": [
      "CurrentUser",
      "EmailLoginFailed"
    ],
    "EmailSignupResult": [
      "CurrentUser",
      "EmailSignupFailed"
    ],
    "FeatureFlag": [
      "BooleanFlag",
      "ErrorFlag",
      "VariantFlag"
    ],
    "GeoViewBase": [
      "GeoView",
      "PublishingEntityGeoView"
    ],
    "LoginDemoUserResult": [
      "CurrentUser",
      "LoginDemoUserFailed"
    ],
    "PerformanceDatum": [
      "FullPerformanceDatum",
      "NullPerformanceDatum"
    ],
    "QuestionHierarchyNode": [
      "Question",
      "QuestionHierarchyParentNode",
      "QuestionHierarchySimulationNode",
      "QuestionHierarchyVisualizationNode"
    ],
    "ResetPasswordResult": [
      "CurrentUser",
      "ResetPasswordFailed"
    ],
    "Respondent": [
      "PrivateRespondent",
      "PublicRespondent"
    ],
    "RespondentBase": [
      "PrivateRespondent",
      "PublicRespondent"
    ],
    "RespondentFeedItemTypedData": [
      "RespondentFeedItemTypedData_BalancingActSimulation",
      "RespondentFeedItemTypedData_Idea",
      "RespondentFeedItemTypedData_Question",
      "RespondentFeedItemTypedData_QuestionSet"
    ],
    "Stat": [
      "EmptyBreakdown",
      "FeatureSettingMissingBreakdown",
      "ProtectedStatBreakdown",
      "StatBreakdown",
      "StatBreakdownLocation",
      "StatValueDelta"
    ],
    "TrackVariableFull": [
      "TrackVariable",
      "TrackVariableWithDistributions"
    ]
  }
};
      export default result;
    