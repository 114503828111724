import * as React from 'react';
import { Alert, Collapse } from 'react-bootstrap';
import classNames from 'classnames';
import './styles.scss';
import { TransitionComponent } from 'react-bootstrap/esm/helpers';
import {
  Btn,
  BtnLink,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { CtaLinkType, CtaLinkProps } from 'core';
import logo from 'client/assets/polco-logo.svg';
import { useLocalStorage } from 'client/shared/helpers/hooks';
import { CollapseBtn } from '../collapse-btn';
import { useId } from 'react-use-id-hook';

export enum WellType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ICON_INFO = 'ICON_INFO',
  ICON_POLCO = 'ICON_POLCO',
  ICON_ERROR = 'ICON_ERROR',
  ICON_SUCCESS = 'ICON_SUCCESS',
  TEXT_BLOCK_WHITE_WITH_BORDER = 'TEXT_BLOCK_WHITE_WITH_BORDER',
  TEXT_BLOCK_GRAY = 'TEXT_BLOCK_GRAY',
  ICON_POLCO_BORDER = 'ICON_POLCO_BORDER',
}

export enum CollapsedWellKeys {
  TRACK_UPSELL = 'TRACK_UPSELL_WELL_COLLAPSED',
  BENCHMARK_SURVEY_ASSESSMENTS = 'BENCHMARK_SURVEY_ASSESSMENTS_WELL_COLLAPSED',
  COMMUNITY_INSIGHT_DASHBOARDS_GPAL = 'COMMUNITY_INSIGHT_DASHBOARDS_GPAL_WELL_COLLAPSED',
}

export interface Props {
  readonly ariaLive?: 'off' | 'polite' | 'assertive';
  readonly className?: string;
  readonly ctaProps?: CtaLinkProps;
  readonly header?: React.ReactNode;
  readonly type: WellType;
  readonly canClose?: boolean; // default: false
  readonly onClosed?: () => void;
  readonly noExtraRightPadding?: boolean;
  readonly noTopMargin?: boolean;
  readonly fontSizeClass?: string;
  readonly headerClass?: string;
  readonly containerClass?: string;
  readonly collapsedKey?: CollapsedWellKeys;
  readonly footerElements?: readonly JSX.Element[];
  readonly expandIconClass?: string;
  readonly icon?: React.ReactNode;
}

const baseClass = 'pn-well';

interface StyleObject {
  readonly customStyle: string;
  readonly variant:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
    | '';
  readonly icon: React.ReactNode;
}

const typeToWellStyle: Record<WellType, StyleObject> = {
  [WellType.SUCCESS]: {
    customStyle: 'text-polco-green-xd',
    variant: 'success',
    icon: <></>,
  },
  [WellType.WARNING]: {
    customStyle: 'text-canary-xd bg-canary-xl border-canary-l',
    variant: 'warning',
    icon: <></>,
  },
  [WellType.ERROR]: {
    customStyle: 'text-error-d',
    variant: 'danger',
    icon: <></>,
  },
  [WellType.INFO]: {
    customStyle: 'border-0 bg-sky-xl text-gray-60',
    variant: 'info',
    icon: <></>,
  },
  [WellType.ICON_INFO]: {
    customStyle: 'bg-polco-green-xl text-gray-60',
    variant: '',
    icon: (
      <MaterialIcon
        className="pr-2"
        icon={MaterialIconName.INFO}
        iconType="material-icons-outlined"
      />
    ),
  },
  [WellType.ICON_POLCO]: {
    customStyle: '',
    variant: 'success',
    icon: (
      <img
        alt="Polco's Logo"
        className={`${baseClass}-polco-logo mr-2`}
        src={logo}
      />
    ),
  },
  [WellType.ICON_POLCO_BORDER]: {
    customStyle: `${baseClass}-green-border`,
    variant: 'success',
    icon: (
      <img
        alt="Polco's Logo"
        className={`${baseClass}-polco-logo mr-2`}
        src={logo}
      />
    ),
  },
  [WellType.ICON_ERROR]: {
    customStyle: 'text-error-d',
    variant: 'danger',
    icon: <MaterialIcon className="pr-2" icon={MaterialIconName.ERROR} />,
  },
  [WellType.ICON_SUCCESS]: {
    customStyle: 'text-polco-green-xd',
    variant: 'success',
    icon: (
      <MaterialIcon
        className="mr-3 font-size-lg"
        icon={MaterialIconName.CHECK_CIRCLE}
      />
    ),
  },
  [WellType.TEXT_BLOCK_WHITE_WITH_BORDER]: {
    customStyle: `${baseClass}-white-with-border p-3`,
    variant: '',
    icon: (
      <MaterialIcon
        className="pr-3 align-self-center"
        icon={MaterialIconName.INSERT_CHART_OUTLINED}
      />
    ),
  },
  [WellType.TEXT_BLOCK_GRAY]: {
    customStyle: 'bg-gray-6',
    variant: '',
    icon: <></>,
  },
};

export const Well: React.FC<Props> = (props) => {
  const wellId = useId();
  const isCollapsible = !!props.collapsedKey;
  const localStorage = useLocalStorage();
  const localStorageCollapsed = props.collapsedKey
    ? localStorage?.getItem(props.collapsedKey)
    : null;
  const [isClosed, setClosed] = React.useState(false);
  const [expanded, setExpanded] = React.useState(localStorageCollapsed === null);
  const { customStyle, variant, icon } = typeToWellStyle[props.type];
  const wellIcon = props.icon ?? icon;
  return (
    <Alert
      aria-live={props.ariaLive}
      className={classNames(
        baseClass,
        !props.noTopMargin && 'mt-4',
        props.className,
        customStyle
      )}
      dismissible={props.canClose}
      id={wellId}
      onClose={() => {
        props.onClosed && props.onClosed();
        setClosed(true);
      }}
      show={!isClosed}
      transition={Collapse as TransitionComponent}
      variant={variant}
    >
      <div
        className={`d-flex ${
          props.ctaProps
            ? 'justify-content-between align-items-center'
            : 'align-items-start'
        }`}
      >
        <div
          className={`d-flex flex-fill ${
            isCollapsible && !expanded ? 'align-items-center' : ''
          } ${props.ctaProps ? 'mr-3' : ''} ${props.containerClass ?? ''}`}
        >
          {/* icon is conditionally shown in different locations as collapsible wells require different text alignment */}
          {!isCollapsible && wellIcon}
          <div className="flex-fill d-flex justify-content-between align-items-center">
            <div className="w-100">
              <div
                className={`d-flex align-items-xl-center justify-content-between flex-xl-row flex-column`}
              >
                <div className={`d-flex ${baseClass}-header align-items-center`}>
                  {isCollapsible && wellIcon}
                  {props.header && (
                    <div
                      className={`font-weight-bold flex-grow-1 ${
                        props.headerClass ?? ''
                      }`}
                    >
                      {props.header}
                    </div>
                  )}
                </div>
                {props.footerElements && !expanded && (
                  <div className="d-flex mt-xl-0 mt-4 flex-column flex-md-row align-items-center">
                    {props.footerElements}
                  </div>
                )}
              </div>

              {expanded && (
                <div
                  className={`${props.fontSizeClass ?? 'font-size-sm'} ${
                    isCollapsible && 'mt-3'
                  }`}
                >
                  {props.children}
                  {isCollapsible && (
                    <div className="d-flex align-items-start mt-4 flex-column flex-md-row align-self-md-start align-self-center align-items-center">
                      {props.footerElements}
                      <Btn
                        action={() => {
                          setExpanded(false);
                          props.collapsedKey &&
                            localStorage?.setItem(props.collapsedKey, 'true');
                        }}
                        className={`${baseClass}-collapse-banner`}
                        customPadding="py-2 px-0"
                        type={ButtonTypes.LINK}
                      >
                        Collapse Banner
                      </Btn>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {isCollapsible && (
            <CollapseBtn
              action={() => {
                if (props.collapsedKey) {
                  if (expanded) {
                    localStorage?.setItem(props.collapsedKey, 'true');
                  } else {
                    localStorage?.removeItem(props.collapsedKey);
                  }
                }
                setExpanded((prev) => !prev);
              }}
              ariaControls={wellId}
              className={`${props.expandIconClass ?? ''} ml-2`}
              expanded={expanded}
            />
          )}
        </div>
        {props.ctaProps && (
          <BtnLink
            className="mt-2"
            target={props.ctaProps.type === CtaLinkType.EXTERNAL ? '_blank' : ''}
            to={props.ctaProps.link}
            type={ButtonTypes.SECONDARY}
          >
            {props.ctaProps.text}
          </BtnLink>
        )}
      </div>
    </Alert>
  );
};
Well.displayName = 'Well';
