import * as React from 'react';
import './styles.scss';

import {
  ClickableDiv,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
const baseClass = 'pn-arrow';

export enum ArrowBackgroundColors {
  WHITE = 'white',
  GRAY = 'gray',
}

export const NextArrow: React.FC<{
  readonly bg?: ArrowBackgroundColors;
  readonly onClick: () => void;
}> = (p) => {
  return (
    <ClickableDiv
      action={p.onClick}
      className={`${baseClass} ${baseClass}-forward position-absolute h-100 d-flex justify-center align-items-center border-0 mod-bg-${p.bg}`}
    >
      <MaterialIcon
        className="text-jungle text-center"
        icon={MaterialIconName.ARROW_FORWARD_IOS}
      />
    </ClickableDiv>
  );
};
NextArrow.displayName = 'NextArrow';
NextArrow.defaultProps = {
  bg: ArrowBackgroundColors.WHITE,
};

export const PrevArrow: React.FC<{
  readonly bg?: ArrowBackgroundColors;
  readonly onClick: () => void;
}> = (p) => {
  return (
    <ClickableDiv
      action={p.onClick}
      className={`${baseClass} ${baseClass}-back position-absolute h-100 d-flex justify-center align-items-center border-0 mod-bg-${p.bg}`}
    >
      <MaterialIcon
        className="text-jungle text-center"
        icon={MaterialIconName.ARROW_BACK_IOS}
      />
    </ClickableDiv>
  );
};
PrevArrow.displayName = 'PrevArrow';
PrevArrow.defaultProps = {
  bg: ArrowBackgroundColors.WHITE,
};
