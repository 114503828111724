import { useLocalStorage } from 'client/shared/helpers/hooks';
import * as React from 'react';
import { Well, WellType } from '../base';

const baseClass = 'pn-messaging-well';
export const MessagingWell: React.FC<{
  readonly className?: string;
  readonly message: string;
}> = (p) => {
  const localStorage = useLocalStorage();
  const lastUsedMessagingWell = localStorage?.getItem('lastMessagingWell');

  return (
    <>
      {p.message && p.message !== lastUsedMessagingWell && (
        <Well
          canClose
          className={`${baseClass} ${p.className} mb-0`}
          noTopMargin
          onClosed={() => localStorage?.setItem('lastMessagingWell', p.message)}
          type={WellType.ICON_INFO}
        >
          {p.message}
        </Well>
      )}
    </>
  );
};
