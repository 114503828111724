import { useEffect, useState } from 'react';
import { useClock } from './use-clock';

export interface Props {
  readonly frequencyMillis: number;
}

export type TimeFunction = (p: Props) => Date;

/**
 * Provides a components contents with a refresh at a given interval
 * useful for triggering rendering events and when there is a feature
 * with aging.
 * Influenced by this gist - https://gist.github.com/jamesfulford/7f3311bd918982e68d911a9c70b27415
 */
export const useCurrentTime: TimeFunction = (p) => {
  const clock = useClock();
  const [currentTime, setTime] = useState(clock.now());

  useEffect(() => {
    // Regularly set time in state
    // and queues re-renders every `frequencyMillis`

    const key = clock.setInterval(() => {
      setTime(clock.now());
    }, p.frequencyMillis);

    // Cleanup interval
    return () => clock.clearInterval(key);

    // Specify dependencies for useEffect
  }, [p.frequencyMillis, setTime, clock]);

  return currentTime;
};
