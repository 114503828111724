import { InMemoryCache } from '@apollo/client/cache';
import { TypedTypePolicies } from './graphql-apollo-client-helpers.g';
// generated by Fragment Matcher plugin
import introspectionResult from './graphql-introspection-result.g';
import _ from 'lodash';

const typePolicies: TypedTypePolicies = {
  Question: {
    fields: {
      schedule: { merge: true },
    },
  },
  CommentsData: {
    fields: {
      comments: {
        keyArgs: (
          args: {
            readonly pagination?: {
              readonly page: number;
              readonly perPage: number;
            };
          } | null
        ) => {
          const paginationString = args?.pagination ? 'paged' : 'all';
          return paginationString;
        },
        merge: paginationParamsMerge,
      },
    },
  },
  QuestionSet: {
    fields: {
      schedule: { merge: true },
      shortUrls: { merge: false },
      createdQuestions: {
        keyArgs: (
          args: {
            readonly pagination?: {
              readonly page: number;
              readonly perPage: number;
            };
            readonly status?: readonly string[];
          } | null
        ) => {
          // Keep a separate cache for each set of supplied statuses
          const statusString = [...(args?.status ?? [])].sort().join(',');
          const paginationString = args?.pagination ? 'paged' : 'all';
          return `${statusString} ${paginationString}`;
        },
        merge: createdQuestionsPaginationMerge,
      },
    },
  },
  QuestionChoiceSet: {
    fields: {
      choices: { merge: false },
      scaleData: { merge: true },
    },
  },
  Survey: {
    fields: {
      benchmarkType: { merge: true },
      shortUrls: { merge: false },
      schedule: { merge: true },
      contents: {
        keyArgs: (
          args: {
            readonly pagination?: {
              readonly page: number;
              readonly perPage: number;
            };
            readonly filterByTopicIds?: readonly string[];
          } | null
        ) => {
          const paginationString = args?.pagination ? 'paged' : 'all';
          const topicsString = [...(args?.filterByTopicIds ?? [])].sort().join(',');
          return `${topicsString} ${paginationString}`;
        },
        merge: paginationParamsMerge,
      },
    },
  },
  PolcoLive: {
    fields: {
      shortUrls: { merge: false },
      schedule: { merge: true },
      createdQuestions: {
        keyArgs: (
          args: {
            readonly pagination?: {
              readonly page: number;
              readonly perPage: number;
            };
            readonly status?: readonly string[];
          } | null
        ) => {
          // Keep a separate cache for each set of supplied statuses
          const statusString = [...(args?.status ?? [])].sort().join(',');
          const paginationString = args?.pagination ? 'paged' : 'all';
          return `${statusString} ${paginationString}`;
        },
        merge: createdQuestionsPaginationMerge,
      },
    },
  },
  PublishingEntity: {
    fields: {
      shareableContentSetsForSidebar: { merge: false },
      thirdPartyRecords: { merge: false },
      socialConnections: { merge: false },
      managingAdmins: { merge: false },
      emailLists: { merge: false },
      ideaSubmissions: {
        keyArgs: (
          args: {
            readonly pagination?: {
              readonly page: number;
              readonly perPage: number;
            };
          } | null
        ) => {
          const paginationString = args?.pagination ? 'paged' : 'all';
          return paginationString;
        },
        merge: paginationParamsMerge,
      },
    },
  },
  PrivateRespondent: {
    fields: {
      subscriptions: { merge: false },
      subscribablePublishers: { merge: false },
    },
  },
  RespondentFeed: {
    fields: {
      items: { merge: false },
    },
  },
  RespondentQuestionVoteData: {
    fields: {
      questions: {
        merge: putNewAtEndAndKeepOldOrder,
      },
    },
  },
  PresidingArea: {
    fields: {
      center: { merge: false },
      shape: { merge: false },
    },
  },
  QuestionHierarchyParentNode: {
    fields: {
      questions: {
        keyArgs: (
          args: {
            readonly filterByTopicIds?: readonly string[];
          } | null
        ) => {
          return [...(args?.filterByTopicIds ?? [])].sort().join(',');
        },
      },
    },
  },
  Query: {
    fields: {
      superAdminBenchmarkReportRequests: {
        merge: false,
      },
    },
  },
  AiAssistantPublisher: {
    keyFields: ['publishingEntityId'],
  },
  TrackVariable: {
    fields: {
      areasData: {
        merge: false,
      },
    },
  },
  TrackAreaData: {
    fields: {
      performanceData: {
        merge(existing = [], incoming) {
          return _.uniqBy([...existing, ...incoming], (val) => val.__ref);
        },
      },
    },
  },
};

/* May be desirable again in the future

function preserveOldAtFrontAndUseNewOrder(
  existing: { __ref: string }[] = [],
  incoming: { __ref: string }[]
): { __ref: string }[] {
  const leftBehind = existing.filter(
    (e) => !incoming.some((i) => i.__ref === e.__ref)
  );
  return [...leftBehind, ...incoming];
}
*/

function putNewAtEndAndKeepOldOrder(
  existing: readonly { readonly __ref: string }[] = [],
  incoming: readonly { readonly __ref: string }[]
): readonly { readonly __ref: string }[] {
  const newItems = incoming.filter(
    (e) => !existing.some((i) => i.__ref === e.__ref)
  );
  const updatedExisting = existing.map(
    (e) => incoming.find((i) => i.__ref === e.__ref) ?? e
  );
  return [...updatedExisting, ...newItems];
}

// Read about Apollos offset based pagination support here: https://www.apollographql.com/docs/react/pagination/offset-based/
// This function is modeled off of https://github.com/apollographql/apollo-client/blob/29d41eb590157777f8a65554698fcef4d757a691/src/utilities/policies/pagination.ts#L28
function paginationParamsMerge(
  existing: readonly unknown[] = [],
  incoming: readonly unknown[],
  data: {
    readonly args: {
      readonly pagination?: { readonly page: number; readonly perPage: number };
    } | null;
  }
) {
  if (data.args?.pagination) {
    const merged = [...existing];
    const { page, perPage } = data.args.pagination;
    for (let i = 0; i < incoming.length; i++) {
      merged[(page - 1) * perPage + i] = incoming[i];
    }
    return merged;
  } else {
    return [...incoming];
  }
}

function createdQuestionsPaginationMerge(
  existing: {
    readonly pagination: Record<string, number>;
    readonly questions: readonly unknown[];
  } = {
    pagination: {},
    questions: [],
  },
  incoming: {
    readonly pagination: Record<string, number>;
    readonly questions: readonly unknown[];
  },
  data: {
    readonly args: {
      readonly pagination?: { readonly page: number; readonly perPage: number };
    } | null;
  }
) {
  if (data.args?.pagination) {
    const mergedQuestions = [...existing.questions];
    const { page, perPage } = data.args.pagination;
    for (let i = 0; i < incoming.questions.length; i++) {
      mergedQuestions[(page - 1) * perPage + i] = incoming.questions[i];
    }
    return {
      pagination: incoming.pagination,
      questions: mergedQuestions,
    };
  } else {
    return incoming;
  }
}

export function buildGraphqlCache() {
  return new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies,
  });
}
