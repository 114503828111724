import * as React from 'react';
import './styles.scss';
import classNames from 'classnames';
import { Button, ButtonProps } from 'react-bootstrap';
import { handlePressEnter } from 'client/shared/core/helpers';
import { SuperUserIcon } from '../super-admin-icon';

/**
 * Button Types support both official bootstrap button variants,
 * AND custom "homemade" button types.
 *
 * If this button type is not an official bootstrap
 * variant, make sure to add a mod-type-<type>
 * style for it.
 */
export enum ButtonTypes {
  PRIMARY = 'primary',
  SECONDARY = 'outline-primary',
  SEAMLESS = 'seamless',
  LINK = 'link',
  DANGER = 'danger',
  SOCIAL_FACEBOOK = 'facebook',
  SOCIAL_GOOGLE = 'google',
  SOCIAL_EMAIL = 'gray-10',
  SHARE_TWITTER = 'share-twitter',
  RESPONDENT = 'outline-gray-20',
  OPTION = 'option',
  SUPERADMIN = 'primary-super-admin',
}

const additionalClassesForType: { readonly [key: string]: string } = {
  [ButtonTypes.LINK]: 'p-0 m-0 d-inline align-baseline border-0',
};

export interface Props {
  readonly ariaLabel?: string;
  readonly ariaControls?: string;
  readonly ariaExpanded?: boolean;
  readonly className?: string;
  readonly danger?: boolean;
  readonly type: ButtonTypes;
  readonly disabled?: boolean;
  readonly size?: 'small' | 'large';
  readonly square?: boolean;
  readonly loading?: boolean;
  readonly dontPreventDefault?: boolean;
  readonly action: (
    evt: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>
  ) => void | Promise<any>;
  readonly children?: React.ReactNode;
  readonly style?: React.CSSProperties;
  readonly iconOnly?: boolean;
  readonly customPadding?: string;
  readonly stopPropagation?: boolean;
  readonly onFocus?: React.FocusEventHandler<HTMLElement>;
  readonly tabIndex?: 0 | -1;
}

function propSizeToBs(size?: 'small' | 'large') {
  switch (size) {
    case 'small':
      return 'sm';
    case 'large':
      return 'lg';
    default:
      return undefined;
  }
}

const baseClass = 'pn-btn';

/**
 * Basic button component.
 */
export const Btn: React.FC<Props> = (props: Props) => {
  const mounted = React.useRef(false);
  const [promiseActionRunning, setActionRunning] = React.useState(false);
  const loading = props.loading || promiseActionRunning;

  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const classes = classNames(
    baseClass,
    loading ? 'is-loading' : 'is-not-loading',
    props.square ? 'is-square' : '',
    props.disabled ? 'is-disabled' : 'is-enabled',
    props.className,
    `mod-size-${props.size || 'default'}`,
    `mod-type-${props.type}`,
    {
      'mod-icon-only': props.iconOnly,
      'seamless-danger': props.type === ButtonTypes.SEAMLESS && props.danger,
    },
    additionalClassesForType[props.type],
    'rounded',
    props.customPadding ? props.customPadding : 'py-2 px-3'
  );

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLButtonElement>) =>
    handleClickOrKeydown(e);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) =>
    handleClickOrKeydown(e);

  const handleClickOrKeydown = async (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>
  ) => {
    if (!props.dontPreventDefault) {
      e.preventDefault();
    }

    if (props.stopPropagation) {
      e.stopPropagation();
    }

    if (!loading) {
      const v = props.action(e);
      if (v && mounted.current) {
        setActionRunning(true);
        try {
          await v;
        } finally {
          if (mounted.current) {
            setActionRunning(false);
          }
        }
      }
    }
  };

  return (
    <Button
      aria-controls={props.ariaControls}
      aria-expanded={props.ariaExpanded}
      aria-label={props.ariaLabel}
      className={classes}
      disabled={props.disabled}
      onClick={handleClick}
      onFocus={props.onFocus}
      onKeyDown={handlePressEnter(handleKeyDown)}
      size={propSizeToBs(props.size)}
      style={props.style}
      tabIndex={props.tabIndex ?? 0}
      variant={
        props.type === ButtonTypes.SUPERADMIN
          ? (ButtonTypes.PRIMARY as ButtonProps['variant'])
          : (props.type as ButtonProps['variant'])
      }
    >
      {props.type === ButtonTypes.SUPERADMIN && (
        <SuperUserIcon className="mr-1" color="white" />
      )}
      <span className={classNames({ invisible: loading })}>{props.children}</span>
      {loading && <span className={`${baseClass}-spinner`} />}
    </Button>
  );
};

Btn.displayName = 'Btn';
