export enum AnalyticsDomain {
  SAFETY = 'SAFETY',
  ECONOMY = 'ECONOMY',
  COMMUNITY_DESIGN = 'COMMUNITY_DESIGN',
  HEALTH_AND_WELLNESS = 'HEALTH_AND_WELLNESS',
  INCLUSIVITY_AND_ENGAGEMENT = 'INCLUSIVITY_AND_ENGAGEMENT',
  EDUCATION_ARTS_CULTURE = 'EDUCATION_ARTS_CULTURE',
  GOVERNANCE = 'GOVERNANCE',
  COMMUNITY_LIVABILITY = 'COMMUNITY_LIVABILITY',
  COMMUNITY_CHARACTERISTICS = 'COMMUNITY_CHARACTERISTICS',
  MOBILITY = 'MOBILITY',
  NATURAL_ENVIRONMENT = 'NATURAL_ENVIRONMENT',
  PARKS_AND_RECREATION = 'PARKS_AND_RECREATION',
  UTILITIES = 'UTILITIES',
}

export type DashboardAnalyticsDomain =
  | AnalyticsDomain.SAFETY
  | AnalyticsDomain.ECONOMY
  | AnalyticsDomain.COMMUNITY_DESIGN
  | AnalyticsDomain.EDUCATION_ARTS_CULTURE
  | AnalyticsDomain.HEALTH_AND_WELLNESS
  | AnalyticsDomain.MOBILITY
  | AnalyticsDomain.NATURAL_ENVIRONMENT
  | AnalyticsDomain.PARKS_AND_RECREATION
  | AnalyticsDomain.INCLUSIVITY_AND_ENGAGEMENT
  | AnalyticsDomain.UTILITIES;

export enum AnalyticsSubdomain {
  ALTERNATE_MODES = 'ALTERNATE_MODES',
  ARTS_CULTURE = 'ARTS_CULTURE',
  AUTO = 'AUTO',
  BROADBAND = 'BROADBAND',
  BUSINESS_VITALITY = 'BUSINESS_VITALITY',
  CLIMATE = 'CLIMATE',
  COMMUNITY_CHARACTERISTICS = 'COMMUNITY_CHARACTERISTICS',
  COMMUNITY_CLEANLINESS = 'COMMUNITY_CLEANLINESS',
  COMMUNITY_LIVABILITY = 'COMMUNITY_LIVABILITY',
  DIVERSITY_INCLUSION = 'DIVERSITY_INCLUSION',
  ECONOMY = 'ECONOMY',
  EDUCATION = 'EDUCATION',
  EMPLOYMENT = 'EMPLOYMENT',
  ENGAGEMENT_CIVIC_SOCIAL = 'ENGAGEMENT_CIVIC_SOCIAL',
  MENTAL_HEALTH = 'MENTAL_HEALTH',
  ENVIRONMENTAL_HEALTH = 'ENVIRONMENTAL_HEALTH',
  EQUITY = 'EQUITY',
  FEELINGS_OF_SAFETY = 'FEELINGS_OF_SAFETY',
  FIRE_EMS = 'FIRE_EMS',
  FOOD_SECURITY = 'FOOD_SECURITY',
  GOVERNANCE = 'GOVERNANCE',
  HEALTHCARE = 'HEALTHCARE',
  HEALTHY_LIFESTYLES = 'HEALTHY_LIFESTYLES',
  HOUSING = 'HOUSING',
  INCOME = 'INCOME',
  LAND_USE = 'LAND_USE',
  LAW_ENFORCEMENT = 'LAW_ENFORCEMENT',
  LIBRARIES = 'LIBRARIES',
  MOBILITY = 'MOBILITY',
  PARKS = 'PARKS',
  PATHS_AND_TRAILS = 'PATHS_AND_TRAILS',
  POWER = 'POWER',
  RECREATION = 'RECREATION',
  RESIDENT_HEALTH = 'RESIDENT_HEALTH',
  SAFETY = 'SAFETY',
  SOLID_WASTE_MANAGEMENT = 'SOLID_WASTE_MANAGEMENT',
  UTILITIES = 'UTILITIES',
  WATER_SEWER = 'WATER_SEWER',
  WELLNESS = 'WELLNESS',
  AIR_QUALITY = 'AIR_QUALITY',
}

export enum AnalyticsValueType {
  AMOUNT = 'AMOUNT',
  CURRENCY = 'CURRENCY',
  PERCENT = 'PERCENT',
}

export enum AnalyticsBenchmarkValue {
  HIGHER = 'HIGHER',
  LESS_FAVORABLE = 'LESS_FAVORABLE',
  LOWER = 'LOWER',
  MORE_FAVORABLE = 'MORE_FAVORABLE',
  MUCH_HIGHER = 'MUCH_HIGHER',
  MUCH_LOWER = 'MUCH_LOWER',
  SIMILAR = 'SIMILAR',
}

export enum DateLevel {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum FipsAreaType {
  COUNTY = 'COUNTY',
  COUNTY_SUBDIVISION = 'COUNTY_SUBDIVISION',
  MSA = 'MSA',
  PLACE = 'PLACE',
  STATE = 'STATE',
  NATION = 'NATION',
}

export enum StatisticType {
  INDEX = 'INDEX',
  INDICATOR = 'INDICATOR',
  SENTIMENT_VALUE = 'SENTIMENT_VALUE',
}

export enum BenchmarkFilter {
  DEFAULT = 'DEFAULT',
  POPULATION = 'POPULATION',
  STATE = 'STATE',
  STATE_AND_POP = 'STATE_AND_POP',
  SES_DEFAULT = 'SES_DEFAULT',
  SES_POPULATION = 'SES_POPULATION',
  SES_STATE = 'SES_STATE',
  SES_STATE_AND_POP = 'SES_STATE_AND_POP',
}

export enum ActivationState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum VisualizationType {
  HISTOGRAM = 'HISTOGRAM',
  LINE = 'LINE',
  PROGRESS = 'PROGRESS',
  MAP = 'MAP',
}

export enum ChartType {
  HISTOGRAM = 'Histogram',
  LINE = 'Line Chart',
  MAP = 'Map',
}

export enum SavedVisualizationFilterStates {
  ALL_CONTENT = 'ALL_CONTENT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum VariableDirection {
  POS = 'POS',
  NEG = 'NEG',
}

export enum VariableDemographicSegment {
  ALL = 'ALL',
  OA = 'OA',
}

export enum VariableDisplayType {
  DEFAULT = 'DEFAULT',
  OLDER_ADULTS = 'OLDER_ADULTS',
}

export enum TrackAreaMode {
  LOCAL = 'LOCAL',
  NATIONAL = 'NATIONAL',
  COMPARISON_GROUP = 'COMPARISON_GROUP',
}

export const trackAreaModeLabels: Record<TrackAreaMode, string> = {
  [TrackAreaMode.LOCAL]: 'Local Data',
  [TrackAreaMode.NATIONAL]: 'National Data',
  [TrackAreaMode.COMPARISON_GROUP]: 'Groups',
};
