import * as React from 'react';
import './styles.scss';
import { getConfig } from 'client/shared/core/config';
import { EnvBanner } from '../env-banner';
import { ErrorContext } from 'client/shared/contexts/error-context';
import { ErrorMessageDisplay } from '../error-message-display';
import { MessagingWell } from 'client/shared/components/messaging-well';
import _ from 'lodash';

export function App(props: { readonly children: JSX.Element }) {
  const { children } = props;
  const config = getConfig();
  const envBannerEnabled = config.envFeatures.environmentBannerEnabled;
  const envBannerName = config.envFeatures.environmentBannerName;
  const wellMessage = config.client.messagingWell;

  const errors = React.useContext(ErrorContext);
  return (
    <div className="polco-app">
      {envBannerEnabled && envBannerName && <EnvBanner envName={envBannerName} />}
      {wellMessage && <MessagingWell message={wellMessage} />}

      {children}
      <ErrorMessageDisplay error={_.last(errors.errors)} />
    </div>
  );
}
