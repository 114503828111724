export interface ILoggerError {
  log(message: string, error?: Error, metadata?: any): void;
}

export class CompositeLogger implements ILoggerError {
  private readonly loggers: readonly ILoggerError[] = [];

  constructor(loggers: readonly ILoggerError[]) {
    this.loggers = loggers;
  }

  log(message: string, error?: Error, metadata?: any) {
    this.loggers.forEach((logger) => {
      logger.log(message, error, metadata);
    });
  }
}
