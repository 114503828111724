import * as React from 'react';
import './styles.scss';
import classNames from 'classnames';
import { Badge } from 'react-bootstrap';
import {
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base/material-icon';

/*
 * At implementation time, only a subset of these pill types have been designed.
 * Until all pill types have been designed it is unclear which styles correspond to which pill types.
 * Similar pill types share CSS styles for now for ease of implementation later.
 * When adding remaining pill types, add missing styles for new pill types and ensure previous uses of pills retain their current styles.
 */
export enum PillTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  LIGHT = 'light',
  UNSTYLED = 'unstyled',
  FACET = 'facet',
  ICON = 'icon',
}

export interface Props {
  readonly type: PillTypes;
  readonly className?: string;
  readonly icon?: MaterialIconName;
  readonly iconClass?: string;
  readonly size?: 'small' | 'default';
  readonly as?: React.ElementType;
  readonly shadow?: boolean;
}

export const baseClass = 'pn-pill';

/**
 * Basic pill component.
 */
export const Pill: React.FC<Props> = (props) => {
  const paddingClass = props.size === 'small' ? 'mod-small' : 'py-2 px-4';
  const shadowClass = props.shadow ? 'mod-shadow' : undefined;

  const classes = classNames(
    baseClass,
    'rounded-pill',
    'd-flex',
    'align-items-center',
    'font-size-sm',
    paddingClass,
    shadowClass,
    props.className
  );

  return (
    <Badge as={props.as} className={classes} pill={true} variant={props.type}>
      {PillTypes.ICON && props.icon && (
        <MaterialIcon
          className={`mr-1 ${props.iconClass ?? ''}`}
          icon={props.icon}
        />
      )}
      {props.children}
    </Badge>
  );
};

Pill.displayName = 'Pill';
