import Rollbar from 'rollbar';
import { isLocalHost } from 'client/shared/core/helpers';
import { getConfig } from 'client/shared/core/config';

function getRollbarConfig(): Rollbar.Configuration {
  return {
    accessToken: getConfig().integrations.rollbarClientToken,
    environment:
      getConfig().envFeatures.environmentBannerName?.toLowerCase() ??
      process.env.NODE_ENV,
    enabled: !isLocalHost,
  };
}

export function getRollbar(): Rollbar {
  return new Rollbar(getRollbarConfig());
}
