// ----------------- UNION HELPER -------------- //

/* 
 * First, a note about enum types.
 * -------------------------------
 * 
 * The declaration 
 ```
 enum Enumm {
   One = 'ONE',
   Two = 'TWO'
 }
 ```
 * compiles to Javascript similar to this:
 ```
 const Enumm = {
   One: 'ONE',
   Two: 'TWO'
 };
 ```
 * The compiled Javascript object is important, because
 * it provides a runtime object from which we can build
 * the union builder/detector. Note that given generic type `T`
 * you can create a mapped type like
 ```
 { [P in keyof T]: string }
 ```
 * but your code can't iterate in over `keyof T`, only 
 * `Object.keys(someObjectWithTypeT)`. Remember that Typescript
 * types are basically just "hints", and don't exist at runtime.
 *
 * Declaring `enum Enumm` also creates the following types:
 * - `Enumm`: effectively `'ONE' | 'TWO'`
 * - `typeof Enumm`: effectively `Record<'One' | 'Two', Enumm>`
 * 
 * However, note that Record<'One' | 'Two', Enumm>` does not maintain
 * the relationship between key and value, i.e. `typeof Enumm[Enumm.One]`
 * should be `'ONE', not `Enumm`.
 * You can get around this by using `typeof {...Enumm}[Enum.One]`, which
 * *does* preserve the relationship. It also adds number indexing, but we
 * can ignore that.
 *
 */

/**
 * A map from union type to the associated payload.
 *
 * Given the type of an enum (where all values are strings),
 * and a union type which discriminates based on the enum using property `type`,
 * provide a type for which keys are the enum values and values are the
 * remainder of the data payload for that key. For example,
 ```
 type Union =
   | { type: Enum.One, value1: number, value2: string }
   | { type: Enum.Two, value3: boolean }
 ```
 * becomes
 ```
 type UnionMap<Enum,Union> = {
   [Enum.One]: { type: Enum.One, value1: number, value2: string }
   [Enum.Two]: { type: Enum.Two, value3: boolean }
 }
 ```
 */
type UnionMap<
  EnumValue extends string,
  Union extends { readonly type: EnumValue },
> = {
  readonly [K in Union['type']]: Extract<Union, { readonly type: K }>;
};

/**
 * The typeof the raw data payload for a particular union case.
 *
 * @remarks Create the UnionMap and access the specific case.
 */
type Raw<
  TEnum extends string,
  TEnumValue extends TEnum,
  TUnion extends { readonly type: TEnum },
> = Omit<UnionMap<TEnum, TUnion>[TEnumValue], 'type'>;

/**
 * Create a union builder/tester object - when `andType()` is called.
 *
 * @param _enumm the declared enum object
 * @param enumAsObj the declared enum object coerced into a
 * maximally type-specific object, which retains the relationship
 * between `keyof _enumm` and specific values - generally provided as
 * `{..._enumm}`
 *
 * @returns an object with `andType<Union>()` function, which produces an
 * object that can build and detect Union cases.
 * 
 * ---
 * 
 * @remarks Generally should be assigned to a `const` value of the same name
 * as the union type it builds.
 * For example,
 ```
 enum Enumm {
   One = 'ONE',
   Two = 'TWO'
 }
 
 type Union1 = { type: Enumm.One, value1: number, value2: string }
 type Union2 = { type: Enumm.Two, value3: boolean }
 type Union = Union1 | Union2
 const Union = unionOfEnum(Enumm, {...Enumm}).andType<Union>();
 ```
 will set up object Union as follows:
 ```
 Union.One({ value1: number, value2: string }) : Union1
 Union.Two({ value3: boolean }) : Union2
 
 Union.One.isA(u: Union): u is Union1 // type-narrowed on true
 Union.Two.isA(u: Union): u is Union2
 
 Union.One.type : Enumm.One
 Union.Two.type : Enumm.Two
 ```
 *
 * Note that `andType<Union>()` must be called to get the union 
 * builder/detector object. This is due to trying to maximize the 
 * amount of type inference. When TS adds partial type inference, 
 * this can get rolled into one call.
 * 
 */
export function unionOfEnum<
  TEnumKey extends string,
  TEnum extends string,
  TEnumObj extends { readonly [k in TEnumKey]: TEnum },
>(_enumm: { readonly [k in TEnumKey]: TEnum }, enumAsObj: TEnumObj) {
  return {
    andType<TUnion extends { readonly type: TEnumObj[TEnumKey] }>() {
      return unionHelper<TEnumObj[keyof TEnumObj], TEnumObj, TEnumKey, TUnion>(
        enumAsObj
      );
    },
  };
}

/**
 *
 * Similar to `unionOfEnum()` but for a list of strings rather than
 * an explicit enum. 
 *
 * @param keys a list of strings that constitute the total list of union
 * values for `type`.
 * @returns an object with `andType<Union>()` function, which produces an
 * object that can build and detect Union cases.
 * 
 * ---
 * 
 * @remarks Example:
 ```
 type Union1 = { type: 'One', value1: number, value2: string }
 type Union2 = { type: 'Two', value3: boolean }
 type Union = Union1 | Union2
 const Union = unionOfStrings(['One', 'Two']).andType<Union>();
 ```
 * will set up object Union as follows:
 ```
 Union.One({ value1: number, value2: string }) : Union1
 Union.Two({ value3: boolean }) : Union2

 Union.One.isA(u: Union) is Union1 // type-narrowed on true
 Union.Two.isA(u: Union) is Union2

 Union.One.type : 'One'
 Union.Two.type : 'Two'
 ```
 *
 */
export function unionOfStrings<TEnumKey extends string>(
  ...keys: readonly TEnumKey[]
) {
  return {
    andType<TUnion extends { readonly type: TEnumKey }>() {
      return unionHelper<
        TEnumKey,
        { readonly [k in TEnumKey]: k },
        TEnumKey,
        TUnion
      >(
        keys.reduce(
          (acc, k) => ({ ...acc, [k]: k }),
          {} as { readonly [k in TEnumKey]: k }
        )
      );
    },
  };
}

/**
 * A function to create the union builder/detector object.
 * @param enumm the enum declaration object that forms the values for
 * `type` cases in the union type.
 * @returns a union builder/detector object
 * 
 * @remarks requires lots of type annotation, like
 ```
 const Union = unionHelper<Enumm,typeof Enumm, keyof typeof Enumm, Union>(Enumm);
 ```
 */
export function unionHelper<
  TEnum extends string,
  TEnumObj extends { readonly [k in TEnumKey]: TEnum },
  TEnumKey extends string,
  TUnion extends { readonly type: TEnum },
>(
  enumm: TEnumObj
): {
  readonly [k in TEnumKey]: {
    readonly isA: (t: TUnion) => t is UnionMap<TEnum, TUnion>[TEnumObj[k]];
    readonly type: TEnumObj[k];
  };
} & {
  readonly [k in TEnumKey]: keyof Raw<TEnum, TEnumObj[k], TUnion> extends never
    ? () => UnionMap<TEnum, TUnion>[TEnumObj[k]]
    : (v: Raw<TEnum, TEnumObj[k], TUnion>) => UnionMap<TEnum, TUnion>[TEnumObj[k]];
} {
  return Object.keys(enumm).reduce<Record<string, Function>>(
    (acc: Record<string, Function>, k: string) => {
      const type = enumm[k as TEnumKey];
      const build = (v: any) => ({ ...(v || {}), type });
      acc[k] = Object.assign(build, {
        isA: (v: any) => v.type === type,
        type,
      });
      return acc;
    },
    {} as Record<string, Function>
  ) as any;
}
