import { Case, unionOfEnum } from 'core';
import { BenchmarkSurveyType, SupportedBenchmarkSurveyType } from '..';

// Topics

export enum QualitativeTopics {
  QUALITY = 'Quality',
  IMPORTANCE = 'Importance',
}

export const ARPA_SPENDING_DOMAIN = 'arpa_spending';

export const TOPIC_ID_QUERY_PARAM = 'topic_id';

// Topic Categories

export enum TopicCategories {
  OVERALL = 'OVERALL',
  COMMUNITY_LIVABILITY = 'COMMUNITY_LIVABILITY',
  ARPA_KPI = 'ARPA_KPI',
  OVERALL_COMMUNITY_QUALITY = 'OVERALL_COMMUNITY_QUALITY',
  COMMUNITY_DESIGN = 'COMMUNITY_DESIGN',
  EMPLOYMENT_AND_FINANCES = 'EMPLOYMENT_AND_FINANCES',
  EQUITY_AND_INCLUSION = 'EQUITY_AND_INCLUSION',
  HEALTH_AND_WELLNESS = 'HEALTH_AND_WELLNESS',
  INFORMATION_AND_ASSISTANCE = 'INFORMATION_AND_ASSISTANCE',
  PRODUCTIVE_ACTIVITIES = 'PRODUCTIVE_ACTIVITIES',
}

export function isTopicCategory(value: string): value is TopicCategories {
  const categories: readonly string[] = Object.values(TopicCategories);
  return categories.includes(value);
}

export function topicCategoriesToString(category: TopicCategories): string {
  switch (category) {
    case TopicCategories.OVERALL:
      return 'Overall';
    case TopicCategories.COMMUNITY_LIVABILITY:
      return 'Community Livability';
    case TopicCategories.ARPA_KPI:
      return 'ARPA Challenge';
    case TopicCategories.OVERALL_COMMUNITY_QUALITY:
      return 'Overall Community Quality';
    case TopicCategories.COMMUNITY_DESIGN:
      return 'Community Design';
    case TopicCategories.EMPLOYMENT_AND_FINANCES:
      return 'Employment and Finances';
    case TopicCategories.EQUITY_AND_INCLUSION:
      return 'Equity and Inclusion';
    case TopicCategories.HEALTH_AND_WELLNESS:
      return 'Health and Wellness';
    case TopicCategories.INFORMATION_AND_ASSISTANCE:
      return 'Information and Assistance';
    case TopicCategories.PRODUCTIVE_ACTIVITIES:
      return 'Productive Activities';
  }
}

// Topic Filters

export enum TopicFilterType {
  IDS = 'IDS',
  CATEGORIES = 'CATEGORIES',
  ALL_NON_QUALITATIVE = 'ALL_NON_QUALITATIVE',
  ALL_CATEGORIZED = 'ALL_CATEGORIZED',
  DOMAIN = 'DOMAIN',
}

export type TopicFilter =
  | Case<TopicFilterType.ALL_NON_QUALITATIVE>
  | Case<TopicFilterType.ALL_CATEGORIZED>
  | Case<
      TopicFilterType.CATEGORIES,
      { readonly categories: readonly TopicCategories[] }
    >
  | Case<TopicFilterType.IDS, { readonly ids: readonly string[] }>
  | Case<TopicFilterType.DOMAIN, { readonly domain: string | null }>;

export const TopicFilter = unionOfEnum(TopicFilterType, {
  ...TopicFilterType,
}).andType<TopicFilter>();

export const TopicOverviewFilterForBenchmarkType: Record<
  SupportedBenchmarkSurveyType,
  TopicFilter
> = {
  [BenchmarkSurveyType.ARPA_BUSINESS]: {
    type: TopicFilterType.CATEGORIES,
    categories: [TopicCategories.COMMUNITY_LIVABILITY],
  },
  [BenchmarkSurveyType.ARPA_RESIDENT]: {
    type: TopicFilterType.CATEGORIES,
    categories: [TopicCategories.COMMUNITY_LIVABILITY],
  },
  [BenchmarkSurveyType.CASOA]: {
    type: TopicFilterType.ALL_CATEGORIZED,
  },
};
