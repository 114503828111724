import { Parsed } from '@polco-us/types/lib/shared/zod';
import z from 'zod';

export enum BalancingActSimulationType {
  BUDGET = 'budget',
  HOUSING = 'housing',
  RECEIPT = 'receipt',
  POINTS = 'points',
  CLIMATE = 'climate',
  PROJECT_MANAGEMENT = 'project_management',
  NON_NUMERICAL = 'nonnumerical',
}

export interface BalancingActClientSimulation {
  readonly id: number;
  readonly name: string;
  readonly locale: string;
  readonly description: string;
  readonly customizations: Record<string, any> | readonly string[] | null;
  readonly slug: string;
  readonly status: string;
  readonly is_pb: boolean;
  readonly simulation_type: BalancingActSimulationType;
  readonly pageviews: number;
  readonly allow_custom_maps_in_modals: boolean;
  readonly require_custom_maps_in_modals: boolean;
  readonly created_at: string;
  readonly updated_at: string;
  readonly can_preview: boolean;
  readonly can_publish: boolean;
  readonly has_submissions_available: boolean;
  readonly url: string;
}

export enum SimulationType {
  BUDGET = 'BUDGET',
  HOUSING = 'HOUSING',
  PRIORITIZE = 'PRIORITIZE',
  RECEIPT = 'RECEIPT',
}

export function simTypeToBASimType(
  simType: SimulationType
): BalancingActSimulationType {
  switch (simType) {
    case SimulationType.BUDGET:
      return BalancingActSimulationType.BUDGET;
    case SimulationType.HOUSING:
      return BalancingActSimulationType.HOUSING;
    case SimulationType.RECEIPT:
      return BalancingActSimulationType.RECEIPT;
    default:
      return BalancingActSimulationType.BUDGET;
  }
}

export function BASimTypeToSimType(
  simType: BalancingActSimulationType
): SimulationType {
  switch (simType) {
    case BalancingActSimulationType.BUDGET:
      return SimulationType.BUDGET;
    case BalancingActSimulationType.HOUSING:
      return SimulationType.HOUSING;
    case BalancingActSimulationType.RECEIPT:
      return SimulationType.RECEIPT;
    default:
      return SimulationType.BUDGET;
  }
}

export const simulationTypeLabel: Record<SimulationType, string> = {
  [SimulationType.BUDGET]: 'Budget Simulation',
  [SimulationType.HOUSING]: 'Housing Simulation',
  [SimulationType.PRIORITIZE]: 'Prioritization',
  [SimulationType.RECEIPT]: 'Taxpayer Receipt',
};

export const SimulationSubcategory = z.object({
  id: z.number(),
  name: z.string(),
  display_order: z.number(),
  amount: z.number(),
  floor_amount: z.number(),
  floor_alert_text: z.string().nullable(),
  ceiling_amount: z.number(),
  ceiling_alert_text: z.string().nullable(),
  alert_threshold: z.number().nullable(),
  alert_type: z.enum(['none', 'increase', 'decrease', 'both', 'threshold']),
  details: z.string().nullable(),
  additional_details: z.string().nullable(),
  customized_question_json: z.record(z.any()).nullable(),
  geojson: z.record(z.any()).nullable(),
  custom_increment_amount: z.number(),
  custom_increment_rate: z.number(),
  custom_increment_rate_for_tax_rate: z.number(),
  is_locked: z.boolean(),
  in_tour: z.boolean(),
  suggestion_box: z.boolean(),
  suggestion_box_mode: z.string().nullable(),
});

export type SimulationSubcategory = Parsed<typeof SimulationSubcategory>;

export const SimulationCategory = z.object({
  id: z.number(),
  name: z.string(),
  type: z.enum(['expense', 'revenue']),
  display_order: z.number(),
  adjustment: z.number(),
  is_locked: z.number(),
  details: z.string().nullable(),
  additional_details: z.string().nullable(),
  subcategories: z.array(SimulationSubcategory),
});

export type SimulationCategory = Parsed<typeof SimulationCategory>;

export const SimulationSubcategoryUpdateSchema = SimulationSubcategory.extend({
  id: z.number().nullable(),
});
export type SimulationSubcategoryUpdate = Parsed<
  typeof SimulationSubcategoryUpdateSchema
>;

export const SimulationCategoryUpdateSchema = SimulationCategory.extend({
  id: z.number().nullable(),
  subcategories: z.array(SimulationSubcategoryUpdateSchema),
});

export type SimulationCategoryUpdate = Parsed<typeof SimulationCategoryUpdateSchema>;

export const FullSimulationUpdateSchema = z.object({
  id: z.number(),
  name: z.string(),
  status: z.enum(['new', 'active', 'inactive', 'archived']),
  slug: z.string(),
  description: z.string().nullable(),
  general_disclaimer: z.string().nullable(),
  revenue_disclaimer: z.string().nullable(),
  expense_disclaimer: z.string().nullable(),
  entry_screen_introduction_text: z.string().nullable(),
  entry_screen_closing_text: z.string().nullable(),
  exit_screen_closing_text: z.string().nullable(),
  adjustment: z.number(),
  timer_minutes: z.number(),
  submission_redirect_url: z.string().nullable(),
  locale: z.string(),
  is_pb: z.boolean(),
  is_revenue_order_randomized: z.boolean(),
  is_expense_order_randomized: z.boolean(),
  is_commenting_enabled: z.boolean(),
  is_sharing_enabled: z.boolean(),
  is_revenue_chart_enabled: z.boolean(),
  is_expense_chart_enabled: z.boolean(),
  is_expense_bar_chart_enabled: z.boolean(),
  is_question_management_enabled: z.boolean(),
  is_budget_hidden: z.boolean(),
  is_footer_hidden: z.boolean(),
  is_budget_submittable_with_deficit: z.boolean(),
  mode: z.nativeEnum(BalancingActSimulationType),
  map_focus_display: z.boolean().nullable(),
  max_items: z.number().nullable(),
  categories: z.array(SimulationCategoryUpdateSchema),
});

export const FullSimulationUpdateRequestBodySchema =
  FullSimulationUpdateSchema.extend({
    categories: z.array(SimulationCategoryUpdateSchema),
  });

export type FullSimulationUpdate = Parsed<typeof FullSimulationUpdateSchema>;

export const FullSimulationSchema = FullSimulationUpdateSchema.extend({
  client_id: z.number(),
  midpoint_redirect_url: z.string().nullable(),
  impact_meter_label: z.string().nullable(),
  impact_meter_details: z.string().nullable(),
  impact_meter_additional_details: z.string().nullable(),
  pb_default_view_mode: z.string(),
  is_help_enabled: z.boolean(),
  is_balance_bar_hidden: z.boolean(),
  is_revenue_section_hidden: z.boolean(),
  is_expense_section_hidden: z.boolean(),
  allow_revenue_category_html: z.boolean(),
  allow_expense_category_html: z.boolean(),
  is_revenue_subtotal_displayed: z.boolean(),
  is_expense_subtotal_displayed: z.boolean(),
  allow_adhoc_meeting_creation: z.boolean(),
  allow_custom_maps_in_modals: z.boolean(),
  require_custom_maps_in_modals: z.boolean(),
  custom_header_html: z.string().nullable(),
  configuration_json: z.record(z.any()).or(z.array(z.any())).nullable(),
  map_enabled: z.number(),
  map_center_lat: z.number().nullable(),
  map_center_lng: z.number().nullable(),
  map_shape_color: z.string().nullable(),
  map_height: z.number().nullable(),
  map_zoom_level: z.number().nullable(),
  geojson: z.record(z.any()).nullable(),
  confetti: z.number(),
  goal_modal: z.string().nullable(),
  bar_behavior: z.string(),
  closed_heading: z.string().nullable(),
  closed_details: z.string().nullable(),
  show_scenario_option_value: z.boolean(),
  show_submission_averages: z.boolean(),
  google_tag_id: z.string().nullable(),
  google_analytics_id: z.string().nullable(),
  google_analytics_measurement_id: z.string().nullable(),
  pageviews: z.number(),
  legacy_id: z.number().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  can_preview: z.boolean(),
  can_publish: z.boolean(),
  subcategory_links: z.array(z.string()),
  has_submissions_available: z.boolean(),
  url: z.string(),
  categories: z.array(SimulationCategory),
});

export type FullSimulationDto = Parsed<typeof FullSimulationSchema>;
