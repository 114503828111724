// can't actually import from client / server, just re-declare here and interface will match
const apiDateTypename = 'GqlDate';
export interface ApiDate {
  readonly raw: number;
  readonly __typename: typeof apiDateTypename;
}

export namespace ApiDate {
  export function fromApi<T extends undefined | null | Pick<ApiDate, 'raw'>>(
    d: T
  ): T extends null | undefined ? Date | null : Date {
    const v = d as any;
    return (v ? new Date(v.raw) : null) as any;
  }

  // WARNING: does NOT set __typename - though if this passes from server to client it will get
  // added automatically
  export function toApi<T extends undefined | null | Date>(
    d: T
  ): T extends null | undefined ? ApiDate | null : ApiDate {
    const v = d as any;
    return (v ? { raw: v.getTime() } : null) as any;
  }
}
