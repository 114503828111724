import React from 'react';

interface Props {
  readonly className?: string;
  readonly color: string;
}

export const SuperUserIcon = (p: Props) => (
  <svg
    className={`${p.className ?? ''}`}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1257_10440)">
      <path
        clipRule="evenodd"
        d="M2.1875 3.89579L8.75 0.979126L15.3125 3.89579V8.27079C15.3125 12.3177 12.5125 16.102 8.75 17.0208C4.9875 16.102 2.1875 12.3177 2.1875 8.27079V3.89579ZM9.70062 6.84017C9.91907 6.9949 10.0427 7.21197 10.0715 7.4914H11.5658C11.5586 7.06879 11.441 6.69699 11.213 6.376C10.9849 6.055 10.6656 5.80444 10.2551 5.62432C9.84705 5.44419 9.36934 5.35413 8.82202 5.35413C8.28429 5.35413 7.80418 5.44419 7.38169 5.62432C6.95919 5.80444 6.62671 6.055 6.38426 6.376C6.1442 6.69699 6.02538 7.07226 6.02778 7.50179C6.02538 8.02601 6.20422 8.44284 6.5643 8.75229C6.92438 9.06173 7.4153 9.2892 8.03704 9.43469L8.84002 9.62867C9.10888 9.69102 9.33573 9.7603 9.52058 9.83651C9.70782 9.91272 9.84945 10.0051 9.94547 10.1136C10.0439 10.2222 10.0931 10.3561 10.0931 10.5154C10.0931 10.6863 10.0391 10.8376 9.93107 10.9692C9.82305 11.1009 9.67061 11.2036 9.47377 11.2775C9.27932 11.3514 9.05007 11.3884 8.78601 11.3884C8.51715 11.3884 8.27589 11.3491 8.06224 11.2706C7.85099 11.1898 7.68176 11.0708 7.55453 10.9138C7.4297 10.7545 7.35888 10.5559 7.34208 10.318H5.83333C5.84534 10.8284 5.97377 11.2567 6.21862 11.6031C6.46588 11.9472 6.80916 12.207 7.24846 12.3825C7.69016 12.558 8.20868 12.6458 8.80401 12.6458C9.40415 12.6458 9.91667 12.5592 10.3416 12.386C10.7689 12.2105 11.0953 11.9645 11.321 11.6482C11.549 11.3295 11.6643 10.9531 11.6667 10.5189C11.6643 10.2233 11.6079 9.96121 11.4974 9.73259C11.3894 9.50397 11.237 9.30537 11.0401 9.13679C10.8433 8.96821 10.6104 8.82618 10.3416 8.71072C10.0727 8.59525 9.77744 8.50288 9.45576 8.4336L8.79321 8.28119C8.63237 8.24655 8.48114 8.20498 8.33951 8.15648C8.19787 8.10568 8.07305 8.04679 7.96502 7.97982C7.857 7.91054 7.77178 7.82856 7.70936 7.73388C7.64935 7.6392 7.62174 7.52835 7.62654 7.40134C7.62654 7.24892 7.67215 7.11267 7.76337 6.99259C7.857 6.8725 7.99143 6.77897 8.16667 6.712C8.34191 6.64273 8.55676 6.60809 8.81121 6.60809C9.1857 6.60809 9.48217 6.68545 9.70062 6.84017Z"
        fill={p.color}
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_1257_10440">
        <rect
          fill="white"
          height="17.5"
          transform="translate(0 0.25)"
          width="17.5"
        />
      </clipPath>
    </defs>
  </svg>
);
