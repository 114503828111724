import * as React from 'react';
import classNames from 'classnames';
import './styles.scss';
import { handlePressEnter } from 'client/shared/core/helpers';

export interface Props {
  readonly className?: string;
  readonly loading?: boolean;
  readonly disabled?: boolean;
  readonly stopPropagation?: boolean;
  readonly size?: 'default' | 'small';
  action?(): any | void;
  readonly refProp?: React.RefObject<HTMLDivElement>;
  onMouseOver?(): any | void;
  onFocus?(): any | void;
  onMouseOut?(): any | void;
  onBlur?(): any | void;
  readonly ariaHidden?: boolean;
}

export interface State {
  readonly promiseActionRunning: boolean;
}

export class ClickableDiv extends React.Component<Props, State> {
  static readonly defaultProps = {
    size: 'default',
  };

  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = { promiseActionRunning: false };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  private startRunning() {
    if (this.mounted) {
      this.setState({ promiseActionRunning: true });
    }
  }
  private stopRunning() {
    if (this.mounted) {
      this.setState({ promiseActionRunning: false });
    }
  }

  render() {
    const p = this.props;
    const loading = p.loading || this.state.promiseActionRunning;
    const stateClass = p.disabled ? 'is-disabled' : 'is-enabled';

    const handleClick = async (
      evt: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
    ) => {
      evt.preventDefault();
      if (p.stopPropagation) {
        evt.stopPropagation();
      }
      if (!loading && !p.disabled) {
        const v = p.action?.();
        if (v && this.mounted) {
          this.startRunning();
          try {
            await v;
          } finally {
            this.stopRunning();
          }
        }
      }
    };

    return loading ? (
      <span
        className={classNames('pn-action-link-loading', stateClass, p.className)}
      >
        Loading...
      </span>
    ) : (
      <div
        aria-hidden={p.ariaHidden}
        className={`
          cursor-${p.action ? 'pointer' : 'default'}
          pn-clickable-div
          ${p.className || ''}
          ${stateClass}
        `}
        onBlur={p.onBlur}
        onClick={handleClick}
        onFocus={p.onFocus}
        onKeyDown={handlePressEnter(handleClick)}
        onMouseOut={p.onMouseOut}
        onMouseOver={p.onMouseOver}
        ref={p.refProp}
        role="button"
        tabIndex={0}
      >
        {p.children}
      </div>
    );
  }
}
