import { z } from 'zod';
export type Uuid = string;

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

// This covers the case of z.coerce.date().parse(null) = true
const datelike = z.union([z.number(), z.string(), z.date()]);
export const zodDate = datelike.pipe(z.coerce.date());

// Secrets stored in SecretsManager are accessed through a <secret name> and returns an object
// To keep it simple we're going to assume that each object contains a single kv pair keyed with the word 'secret'
export const SECRET_REF = 'secret' as const;
