import { Nonce } from './util';
import { isLocalHost, MouseFlowType } from 'client/shared/core/helpers';

export function mouseflowInit(nonce: Nonce) {
  (window as any)._mfq = (window as any)._mfq || [];
  (function () {
    const mf = document.createElement('script');
    mf.type = 'text/javascript';
    mf.async = true;
    mf.src =
      'https://cdn.mouseflow.com/projects/83a70a51-0856-4ecb-8532-777ad5be0f45.js';
    mf.setAttribute('nonce', nonce);
    document.getElementsByTagName('head')[0].appendChild(mf);
  })();
}

// Add superadmin tag to Mouseflow
export const tagSuperAdminOnMouseflow = () => {
  const mfq = (window as any)._mfq;

  if (mfq?.length > 0) {
    mfq.push(['tag', 'superadmin']);
  }
};

// Force adding a new record to MouseFlow
export const recordMouseFlow = (mouseFlowConfig: MouseFlowType) => {
  const mfq = (window as any)._mfq;

  if (mfq.length > 0 && !isLocalHost) {
    mfq.push(['tag'], mouseFlowConfig.tag);
    mouseFlowConfig.customVariable &&
      mfq.push(
        ['setVariable'],
        mouseFlowConfig.customVariable?.key,
        mouseFlowConfig.customVariable?.value
      );
    mouseFlowConfig.pageView && mfq.push(['newPageView', mouseFlowConfig.pageView]);
    mfq.push(['start']);
  }
};
